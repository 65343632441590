import { Box, CircularProgress, } from "@mui/material";
import React from "react";

function SectionLoader() {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
        }}>
            <CircularProgress />
        </Box>
    );
}

export default SectionLoader;