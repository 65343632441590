import { Error } from "actions/actions";
import { Namespaces } from "../../locales/translations";
import { FIREBASE_WRONG_PASSWORD, NOT_FOUND_ERROR, UNAUTHORIZED_ERROR } from "constants/api_errors";
import i18next from "i18next";
import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ActionResultContext = {
    variant: AlertColor;
    message: string;
}

const initialState: ActionResultContext = {
    variant: "info" as "info",
    message: "",
};

const tContext = { ns: Namespaces.snacks };

/**
 * Used to display snackbars and give feedback to user after an action
 * Do not enter the cases when there is a redirect, pass the `actionResult` message in the location state instead
 */

export const actionsResultsSlice = createSlice({
    name: "actions_result",
    initialState: initialState,
    reducers: {
        resetActionResult: (state) => {
            state.variant = "info" as "info";
            state.message = "";
        },
        completeProfile: (state) => {
            state.variant = "success";
            state.message = i18next.t("complete_profile.success", tContext);
        },
        setSignUpError: (state, { payload }: PayloadAction<Error>) => {
            const messageKey = payload.code === NOT_FOUND_ERROR.code ? `sign_up.${NOT_FOUND_ERROR.body}` : "unknown_error";
            state.variant = "error";
            state.message = i18next.t(messageKey, { ns: Namespaces.snacks });
        },
        setSignInLinkSent: (state, { payload }: PayloadAction<string>) => {
            state.variant = "success";
            state.message = i18next.t("sign_in_link.success.body", tContext) + payload;
        },
        setSignInSMSSent: (state, { payload }: PayloadAction<string>) => {
            state.variant = "success";
            state.message = i18next.t("sign_in_sms.success.body", tContext) + payload;
        },
        setSignInError: (state, { payload }: PayloadAction<Error>) => {
            if (payload.body === FIREBASE_WRONG_PASSWORD) {
                state.variant = "error";
                state.message = "L'adresse email et le mot de passe ne correspondent pas."
            } else {
                state.variant = "error";
                state.message = "Impossible de contacter notre serveur. Es-tu bien connecté·e à internet?"
            }
        },
        setError: (state, { payload: error }: PayloadAction<string | null>) => {
            state.variant = 'error';
            state.message = error || '';
        },
    }
})


export const actionsResultsActions = actionsResultsSlice.actions;

const actionsResultsReducer = actionsResultsSlice.reducer;

export default actionsResultsReducer;