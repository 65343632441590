import { Box, styled } from "@mui/material";
import React from "react";

export default styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: 'primary.main',
});