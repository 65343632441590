import Reward from "./Reward";

export enum PurchaseStatuses {
  PURCHASED = "purchased",
  SENT = "sent",
  RECEIVED = "received",
};

type Purchase = {
  id: string;
  reward: Reward;
  status: PurchaseStatuses;
  timeline: { [status in PurchaseStatuses]: number };
  getShopLink(): string;
}

export default Purchase;