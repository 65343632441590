import urls from '../constants/urls';
import User, { CompleteProfileData, UserData, UserRoles } from 'models/User';
import { handleAPIResponse, Error, fetchAPI } from './actions';
import { BAD_REQUEST_ERROR, CONFLICT_ERROR, UNAUTHORIZED_ERROR } from '../constants/api_errors';
import { Dictionary } from 'helpers/types';
import { getMacrolanguage } from 'locales/translations';
import { getAuth, sendSignInLinkToEmail, signInWithCustomToken, isSignInWithEmailLink, signInWithEmailLink, signInWithPhoneNumber, signOut, RecaptchaVerifier, ConfirmationResult, EmailAuthProvider, linkWithCredential, ApplicationVerifier } from 'firebase/auth';
import queryString, { StringifiableRecord, stringifyUrl } from 'query-string';
import { UserActions } from 'store/reducers/user';
import { AppDispatch } from 'store/store';
import { actionsResultsActions } from 'store/reducers/actions_result';
import i18next, { TOptions } from 'i18next';
import { Namespaces } from '../locales/translations';




const signUp = (coconCode: string) => async (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  console.debug("signing up", coconCode);

  let userData: Dictionary = {
    cocon: {
      joinCode: coconCode,
    },
    locale: getMacrolanguage(navigator.language),
  };

  const url = `${urls.API.V4}/user`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(userData)
    });

    const { token }: { token: string } = await handleAPIResponse(response);

    const auth = getAuth();
    const userCredential = await signInWithCustomToken(auth, token);

    dispatch(UserActions.setSignedUp());
  } catch (e: any) {
    console.error(e);
    dispatch(actionsResultsActions.setSignInError(e));
    dispatch(UserActions.setError(e));
  }

};

const login = (pin: string, password: string) => (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading()); // start loading state

  const url = queryString.stringifyUrl({
    url: `${urls.API.V4}/user/sign-in`,
    query: {
      pin: pin,
      password: password,
    },
  });

  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then(handleAPIResponse)
    .then(({ token }: { token: string }) => {
      const auth = getAuth();
      return signInWithCustomToken(auth, token);
    })
    .then(() => {
      dispatch(UserActions.setSignedIn()); // set signed in state
    })
    .catch((error) => {
      const appError = error as Error;
      dispatch(actionsResultsActions.setSignInError(appError.body));
      dispatch(UserActions.setError(appError.body));
      console.error(appError);
    });
};


const checkUserExists = async (params: StringifiableRecord) => {
  try {
    const url = stringifyUrl({
      url: `${urls.API.V4}/user/check-exists`,
      query: params,
    });

    const response = await fetch(url, {
      method: 'GET',
    });

    if (response.status === 404) {
      return false;
    }
    else {
      return true;
    }
  }
  catch (e) { // 404 error if no user matches params
    return false;
  }
}

const sendSignInLink = (email: string) => async (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  const getTranslation = (key: string, success?: boolean, context?: TOptions) => i18next.t(`sign_in_link.${success ? "success" : "fail"}.${key}`, { ns: Namespaces.snacks, ...context });

  // look for existing user with this phone number
  let params: StringifiableRecord = {
    email: email,
  };

  const exists = await checkUserExists(params);

  if (!exists) {
    dispatch(UserActions.setError(getTranslation("no_matching_user", false)));
    dispatch(actionsResultsActions.setError(getTranslation("no_matching_user", false)));
    return null;
  }

  const actionCodeSettings = {
    url: `${urls.WEBAPP}/login`,
    handleCodeInApp: true,
  };

  const auth = getAuth();
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    dispatch(UserActions.stopLoading());
    //tell the user that the link is sent 
    dispatch(actionsResultsActions.setSignInLinkSent(email));
  } catch (e: any) {
    const message = e.message as string;
    dispatch(UserActions.setError(message));
    dispatch(actionsResultsActions.setError(message));
  }
}


const resendSignInSMS = (phoneNumber: string) => async (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  let params: StringifiableRecord = {
    phone: phoneNumber,
  };

  const exists = await checkUserExists(params);

  if (!exists) {
    dispatch(UserActions.setError(i18next.t("sign_in_sms.fail.no_matching_user", { ns: Namespaces.snacks })));
    dispatch(actionsResultsActions.setError(i18next.t("sign_in_sms.fail.no_matching_user", { ns: Namespaces.snacks })));
    return null;
  }

  const auth = getAuth();
  auth.languageCode = 'fr';

  const appVerifier: ApplicationVerifier = {
    type: 'dummy',
    verify: () => Promise.resolve('dummy-response'),
  };

  return signInWithPhoneNumber(auth, phoneNumber, appVerifier).then((confirmationResult) => {
    window.localStorage.setItem('phoneNumberForSignIn', phoneNumber);
    dispatch(UserActions.stopLoading());
    dispatch(actionsResultsActions.setSignInSMSSent(phoneNumber));
    console.log("SMS sent", confirmationResult);
    return confirmationResult;
  }).catch((error) => {
    console.log("Error sending SMS", error);
    // Handle error
    return null;
  });
};


const sendSignInSMS = (phoneNumber: string) => async (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  let params: StringifiableRecord = {
    phone: phoneNumber,
  };

  const exists = await checkUserExists(params);

  console.log("exists", exists);

  if (!exists) {
    dispatch(UserActions.setError(i18next.t("sign_in_sms.fail.no_matching_user", { ns: Namespaces.snacks })));
    dispatch(actionsResultsActions.setError(i18next.t("sign_in_sms.fail.no_matching_user", { ns: Namespaces.snacks })));
    return null;
  }

  const auth = getAuth();
  auth.languageCode = 'fr';


  const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
  recaptchaVerifier.render();

  return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
    .then((confirmationResult) => {
      window.localStorage.setItem('phoneNumberForSignIn', phoneNumber);
      dispatch(UserActions.stopLoading());
      dispatch(actionsResultsActions.setSignInSMSSent(phoneNumber));
      console.log("sms sent", confirmationResult)
      return confirmationResult;
    })
    .catch((e: any) => {
      dispatch(actionsResultsActions.setError(e));
      console.error("failed sending sms", e);

      // Reset the reCAPTCHA
      recaptchaVerifier.clear();
      new RecaptchaVerifier('recaptcha-container', {}, auth);

      return null;
    });

};

const logInWithEmailLink = (link: string) => (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());
  const auth = getAuth();

  let email = window.localStorage.getItem('emailForSignIn');

  if (!email) {
    dispatch(UserActions.setError("No email entered from this app"));
    dispatch(actionsResultsActions.setError("No email entered from this app"));
    return;
  }

  dispatch(UserActions.stopLoading());
  signInWithEmailLink(auth, email, link)
    .then((result) => {
      window.localStorage.removeItem('emailForSignIn');
      dispatch(UserActions.setSignedIn());
      return true;
    })
    .catch((e: any) => {
      dispatch(UserActions.setError(e));
      dispatch(actionsResultsActions.setError(e));
      return false;
    });
}


const logInWithPhoneNumber = (confirmationResult: ConfirmationResult, verificationCode: string) => async (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  try {
    await confirmationResult.confirm(verificationCode);

    dispatch(UserActions.stopLoading());

    return true;
  } catch (e: any) {
    const message = e.message as string;
    dispatch(UserActions.setError(message));
    dispatch(actionsResultsActions.setError(message));
    return false;
  }
};

/**
 * Load the profile of the user
 */
const getProfile = () => {
  return (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    const url = `${urls.API.V3}/user/${userId}`;
    return fetchAPI(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(body => {
        const user: User = new User(body);
        if (Object.values(UserRoles).indexOf(user.role) < 0) { // unauthorized user
          throw UNAUTHORIZED_ERROR;
        }
        dispatch(UserActions.setProfile(user));
      })
      .catch(error => {
        dispatch(UserActions.setError(error));
        console.debug("Failed loading user:", error);
      });
  };
};

const signUserOut = async () => {
  const auth = getAuth();
  await signOut(auth);
};

const dispatchLogOut = () => ((dispatch: AppDispatch) => {
  signUserOut()
    .then(() => {
      dispatch(UserActions.setSignOut());
    });
});

const verifyEmailAddress = (token: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    return fetch(`${urls.API.V3}/user/verify-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': token,
      },
    })
      .then(handleAPIResponse)
      .then((userData: UserData) => {
        const user = new User(userData);
        dispatch(UserActions.setProfile(user));
        return true;
      })
      .catch(e => {
        const error: Error = {
          code: e.code === UNAUTHORIZED_ERROR.code ? BAD_REQUEST_ERROR.code : CONFLICT_ERROR.code,
          body: e.code === UNAUTHORIZED_ERROR.code ? "invalid_link" : "verify_email.already_done",
        }
        dispatch(UserActions.setError(error.body));
        dispatch(actionsResultsActions.setError(error.body));
        return false;
      });
  };
};

const completeAddress = (profileData: CompleteProfileData, token: string) => (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  fetch(`${urls.API.V3}/user/complete-profile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Token': token,
    },
    body: JSON.stringify(profileData),
  })
    .then(() => {
      dispatch(UserActions.stopLoading());
      dispatch(UserActions.completeProfileSuccess());
    })
    .catch((error) => {
      dispatch(UserActions.setError(error));
      dispatch(actionsResultsActions.setError(error));
      console.debug("Failed completing profile:", error);
    });
};

const completeProfile = (profileData: Dictionary) => (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  fetchAPI(`${urls.API.V3}/user/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profileData),
  })
    .then((userData: UserData) => {
      const user = new User(userData);
      dispatch(UserActions.setProfile(user));
      dispatch(actionsResultsActions.completeProfile());
    })
    .catch((error) => {
      dispatch(UserActions.setError(error));
      dispatch(actionsResultsActions.setError(error));
      console.debug("Failed completing profile:", error);
    });
};

const resetPassword = (token: string, email: string, password: string) => (dispatch: AppDispatch) => {
  dispatch(UserActions.startLoading());

  fetch(`${urls.API.V3}/user/update-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Token': token,
    },
    body: JSON.stringify({
      password: password,
    }),
  })
    .then(() => {
      dispatch(UserActions.resetPasswordSuccess());
    })
    .catch((error) => {
      dispatch(UserActions.setError(error));
      dispatch(actionsResultsActions.setError(error));
      console.debug("Failed resetting password:", error);
    });
};

const UserAction = {
  signUp,
  login,
  getProfile,
  sendSignInLink,
  sendSignInSMS,
  logInWithEmailLink,
  logInWithPhoneNumber,
  signUserOut,
  dispatchLogOut,
  verifyEmailAddress,
  completeAddress,
  completeProfile,
  resetPassword,
  resendSignInSMS
};

export default UserAction;