import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Reward from "../../../models/Shop/Reward";
import { LoadableContext, RootState } from "../../store";

type selectedRewardContext = LoadableContext & {
    data: Reward | null,
};

const initialState: selectedRewardContext = {
    data: null,
    loading: false,
    error: null,
};


export const selectedRewardSlice = createSlice({
    name: 'selected_reward',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        set: (state, { payload: reward }: PayloadAction<Reward | null>) => {
            state.loading = false;
            state.data = reward;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedRewardActions = selectedRewardSlice.actions;

const SelectedRewardReducer = selectedRewardSlice.reducer;

export default SelectedRewardReducer;