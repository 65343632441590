import React, { MouseEvent } from 'react';
import { CircularProgress, Button, Theme, Box, } from '@mui/material';
import theme from 'constants/theme';

interface ActionButtonProps {
    onClick?: React.MouseEventHandler;
    children: any;
    color: "inherit" | "primary" | "secondary" | "gradient" | undefined;
    disabled?: boolean;
    loading?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    style?: any;
    icon?: React.ReactNode;
    href?: string;
}

function ActionButton(props: ActionButtonProps) {

    const { children, color, disabled, loading, style, icon, type, href, onClick } = props;

    const buttonDisabled = disabled || loading;

    return (
        <Box
            style={{
                position: "relative",
                ...style,
            }}
            >
            {href ? // link
                <Button
                    onClick={onClick || undefined}
                    variant="contained"
                    disabled={buttonDisabled}
                    type={type}
                    href={href}
                    startIcon={icon}
                    // className={classes.button}
                    {...(color === "gradient" && !buttonDisabled ? {
                        sx: {
                            color: "white.main",
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
                            backgroundSize: `calc(100% + 100px)`,
                            transition: `background-position .5s`,
                            // "&:hover": { backgroundPosition: -100 },
                        }
                    } : { // mui color
                        color: color === "gradient" ? undefined : color
                    }
                    )}
                >
                    {children}
                </Button>
                : // simple button
                <Button
                    onClick={onClick || undefined}
                    variant="contained"
                    disabled={buttonDisabled}
                    type={type}
                    startIcon={icon}
                    // className={classes.button}
                    {...(color === "gradient" && !buttonDisabled ? {
                        sx: {
                            color: "white.main",
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%)`,
                            backgroundSize: `calc(100% + 100px)`,
                            transition: `background-position .5s`,
                            // 
                        }
                    } : { // mui color
                        color: color === "gradient" ? undefined : color
                    }
                    )}
                >
                    {children}
                </Button>
            }
            {loading ?
                <CircularProgress 
                    size={24} 
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -1.5,
                        marginLeft: -1.5,
                    }} 
                    />
                :
                null
            }
        </Box>
    );
}

// const useStyles = makeStyles((theme: Theme) => createStyles({
//     button: {
//         flex: 1,
//         "&:hover": { 
//             backgroundPosition: -100,
//         },
//     },
//     buttonProgress: {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         marginTop: -12,
//         marginLeft: -12,
//     },
// }));

export default ActionButton;