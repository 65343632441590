import { Button, Container, Dialog, DialogActions, DialogTitle, Grid, Typography } from "@mui/material";
import SectionLoader from "components/_include/SectionLoader";
import { Namespaces } from "locales/translations";
import User from "models/User";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CommunityStats from "./CommunityStats/CommunityStats";
import RankProgress from "./Ranks/RankProgress";
import PeopleIcon from '@mui/icons-material/People';
import SlideUpTransition from "components/_include/Transitions/SlideUpTransition";
import ActionButton from "components/_include/ActionButton";
import CompleteProfileDialog from "components/Users/Profile/CompleteProfile/CompleteProfileDialog";
import UserAction from "actions/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SignOutDialog from "./SignOutDialog";

type HomePageProps = {};

function HomePage(props: HomePageProps) {
    const userLoading = useAppSelector((state) => state.users.loading);
    const statsLoading = useAppSelector((state) => state.stats.loading);
    const user = useAppSelector((state) => state.users.data);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.glossary, Namespaces.titles]);

    const [signOutDialogOpen, toggleSignOutDialog] = useState(false);
    const [completeProfileDialogOpen, toggleCompleteProfileDialog] = useState(false);

    const isProfileComplete = user?.email || undefined;

    const handleSignOut = () => {
        if (!isProfileComplete) {
            toggleCompleteProfileDialog(true);
        } else {
            toggleSignOutDialog(true);
        }
    };

    return (
        <Container>
            {userLoading || statsLoading ? (
                <SectionLoader />
            ) : (
                <Grid container justifyContent="center" paddingTop={2}>
                    {!isNaN(Number(user?.pin)) && (
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {t("your_cocon_pin", {
                                    ns: Namespaces.glossary,
                                    pin: user!.getPin(),
                                })}
                            </Typography>
                            <Typography variant="h5">
                                {t("your_password", {
                                    ns: Namespaces.glossary,
                                    password: user!.password,
                                })}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <RankProgress />
                    </Grid>
                    <Grid item xs={7}>
                        <CommunityStats />
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item sm={12} md={6} textAlign="center">
                                <ActionButton
                                    color="gradient"
                                    icon={<PeopleIcon />}
                                    onClick={() => toggleCompleteProfileDialog(true)}
                                >
                                    {t("update_my_profile", { ns: Namespaces.actions })}
                                </ActionButton>
                            </Grid>
                            <Grid item sm={12} md={6} textAlign="center">
                                <Button color="error" onClick={handleSignOut}>
                                    {t("sign_out", { ns: Namespaces.actions })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <SignOutDialog
                open={signOutDialogOpen}
                onClose={() => {
                    toggleSignOutDialog(false);
                }}
            />
            <CompleteProfileDialog
                open={completeProfileDialogOpen}
                onClose={() => {
                    toggleCompleteProfileDialog(false);
                }}
            />
        </Container>
    );
}

export default HomePage;
