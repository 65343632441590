import Partner from "./Partner";
import Store from "./Store";

type Reward = {
    id: string;
    name: string;
    description: string;
    category: RewardsCategories;
    tags: string[];
    imageURL: string;
    cost: number;
    realPrice?: number;
    partner: Partner;
    promoCode?: string;
    stores?: Store[];
    quantity?: number;
    referralLink?: string;
    expiryDate?: number;
}

export default Reward;

export enum RewardsCategories {
    E_COMMERCE = 'e-commerce',
    PHYSICAL = 'physical',
    SENT_BY_FICHA = 'sent_by_ficha',
    LOTTRI = 'lottri',
}

export enum RewardFilter {
    CATEGORY = "category",
    COST_MIN = "costMin",
    COST_MAX = "costMax",
    AFFORDABLE = "affordable",
    NEW = "new",
    LOCATION = "within_km",
    FAVORITES = "favorites",
}

export interface RewardsFilters {
    [RewardFilter.CATEGORY]: RewardsCategories[];
    [RewardFilter.COST_MIN]?: number;
    [RewardFilter.COST_MAX]?: number;
    [RewardFilter.AFFORDABLE]?: boolean;
    [RewardFilter.NEW]?: boolean;
    [RewardFilter.LOCATION]: {
        selected: boolean;
        latitude?: number;
        longitude?: number;
        radius?: number;
    };
    [RewardFilter.FAVORITES]: boolean;
}

export function getShopLink(reward: Reward): string {
    const referralURL = reward.referralLink;
    const websiteURL = reward.partner.website || "";
    const redirectURL = referralURL || websiteURL;
    return redirectURL;
}