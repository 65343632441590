import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Purchase, { PurchaseStatuses } from "../../../models/Shop/Purchase";
import { LoadableContext, RootState } from "../../store";

const purchasesAdapter = createEntityAdapter<Purchase>({
    selectId: purchase => purchase.id,
    sortComparer: (p1, p2) => {
        if (p1.status !== p2.status) { // received purchases (past purchases) at the end
            if (p1.status === PurchaseStatuses.RECEIVED) return 1;
            if (p2.status === PurchaseStatuses.RECEIVED) return -1;
        }

        return p2.timeline[p2.status] - p1.timeline[p1.status]; // sort by date desc
    }
});

type PurchasesContext = LoadableContext & {
    currentPurchasesCount: number,
};

const initialState: PurchasesContext = {
    currentPurchasesCount: 0,
    loading: false,
    error: null,
};


export const purchasesSlice = createSlice({
    name: 'purchases_list',
    initialState: purchasesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: purchases }: PayloadAction<Purchase[]>) => {
            state.loading = false;
            purchasesAdapter.setAll(state, purchases);
            state.currentPurchasesCount = purchases.length;
        },
        addToList: (state, { payload: purchases }: PayloadAction<Purchase[]>) => {
            state.loading = false;
            purchasesAdapter.addMany(state, purchases);
        },
        removeFromList: (state, { payload: purchaseId }: PayloadAction<string>) => {
            purchasesAdapter.removeOne(state, purchaseId);
        },
        updateItem: (state, { payload: { purchaseId, data } }: PayloadAction<{ purchaseId: string, data: Partial<Purchase> }>) => {
            purchasesAdapter.updateOne(state, {
                id: purchaseId,
                changes: data,
            });

            if (data.status === PurchaseStatuses.RECEIVED) { // purchase was marked as received
                state.currentPurchasesCount -= 1;
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const PurchasesActions = purchasesSlice.actions;

export const {
    selectAll: selectAllPurchases,
    selectById: selectPurchaseById,
    selectIds: selectPurchasesIds
} = purchasesAdapter.getSelectors((state: RootState) => state.purchases.list)

const PurchasesReducer = purchasesSlice.reducer;

export default PurchasesReducer;