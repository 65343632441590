import { ReactElement } from 'react';
import { Tab, TabProps, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

interface LinkTabProps extends TabProps {
    icon: ReactElement;
    label?: string;
    to?: string;
}

function LinkTab(props: LinkTabProps) {
    const theme = useTheme();

    return (
        <Tab
            {...props}
            component={({ to, ...props }) => <Link to={to} {...props} />}
            wrapped
            sx={{
                my: 0,
                mx: {
                    xs: 1,
                    md: 2,
                },
                minWidth: (theme) => ({
                    xs: theme.spacing(4),
                    sm: theme.spacing(12),
                    md: theme.spacing(22),
                }),
                padding: 0,
                paddingTop: 1,
                flexDirection: "row",
                justifyContent: "center",
                textTransform: 'none',
                "&.MuiTab-labelIcon": {
                    fontFamily: "CircularStd",
                    fontWeight: "bold",
                    fontSize: 18,
                },
                color: "rgba(255, 255, 255, 0.6)",
                "&.Mui-selected": {
                    color: theme.palette.common.white,
                },
                "& svg:first-of-type": {
                    marginBottom: ".1rem !important",
                    WebkitFilter: `drop-shadow(2px 2px 0px ${theme.palette.primary.main})`,
                    filter: `drop-shadow(2px 2px 0px ${theme.palette.primary.main})`,
                    [theme.breakpoints.up('md')]: {
                        marginRight: theme.spacing(1.5),
                    }
                }
            }}
        />
    );
}

export default LinkTab;