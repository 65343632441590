import { Box, Container, } from "@mui/material";
import MessageAction from "actions/messages";
import SectionLoader from "components/_include/SectionLoader";
import Message, { MessageAttachmentType } from "models/Message";
import React, { useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import LinkBubble from "./MessagesBubbles/LinkBubble";
import MediaBubble from "./MessagesBubbles/MediaBubble";
import MessageBubble from "./MessagesBubbles/MessageBubble";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectAllMessages, selectMessageById, selectMessagesIds } from "store/reducers/messages/list";
import MessageItem from "./MessageItem";


interface CoconChatPageProps {

}

function CoconChatPage(props: CoconChatPageProps) {

    const messagesIds = useAppSelector(selectMessagesIds);
    const firstMessagemessage = useAppSelector(state => selectMessageById(state, messagesIds[0]));
    const messagesLoading = useAppSelector(state => state.messages.list.loading);
    const firstItemIndex = useAppSelector(state => state.messages.list.firstItemIndex);

    const dispatch = useAppDispatch();

    const listRef = useRef<VirtuosoHandle>(null);

    useEffect(() => {
        dispatch(MessageAction.listUserMessages(false, undefined, 20)); // load 20 last messages by default
    }, []);

    const ListComponents = useMemo(() => ({
        Header: () => <div style={{ textAlign: 'center', padding: '1rem' }}>{messagesLoading ? "Chargement..." : ""}</div>,
        Footer: () => <Box sx={{ height: (theme) => theme.spacing(8), }} />,
    }), []);


    const loadOlderMessages = () => {
        const nbMessagesToLoad = 10;
        if (firstMessagemessage)
            dispatch(MessageAction.listUserMessages(false, firstMessagemessage.timestamp, nbMessagesToLoad));
    };

    if (messagesLoading) {
        return (<SectionLoader />);
    }

    return (
        <Container
            maxWidth="md"
            sx={{
                my: 0,
            }}
        >
            <Virtuoso
                style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                ref={listRef}
                components={ListComponents}
                initialTopMostItemIndex={messagesIds.length - 1}
                firstItemIndex={firstItemIndex}
                data={messagesIds}
                startReached={loadOlderMessages}
                itemContent={(index, messagesId) => (
                    <MessageItem messageId={messagesId} key={index} />
                )}
                followOutput={"smooth"}
            />
        </Container>
    );
}

export default CoconChatPage;