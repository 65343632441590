import { Box, BoxProps, } from "@mui/material";

type MessageWrapperProps = Pick<BoxProps, "children"> & {
    isCoconMessage: boolean;
}

function MessageWrapper(props: MessageWrapperProps) {
    const { isCoconMessage, children } = props;

    return (
        <Box
            sx={{
                px: 4,
                paddingBottom: 3,
                display: "flex",
                ...(isCoconMessage ? {
                    paddingRight: 8,
                    justifyContent: "flex-start",
                } : {
                    paddingLeft: 8,
                    justifyContent: "flex-end",

                })
            }}
        >
            {children}
        </Box>
    )
}

export default MessageWrapper;