import { Box, Typography } from "@mui/material";
import Message from "models/Message";
import MessageWrapper from "./MessageWrapper";


type MessageBubbleProps = {
    message: Message;
}

function MessageBubble(props: MessageBubbleProps) {
    const { message } = props;

    const isCoconMessage = message.from !== "user";

    return (
        <MessageWrapper isCoconMessage={isCoconMessage}>
            <Box
                px={2}
                py={2}
                borderRadius={4}
                boxShadow={7}
                bgcolor={isCoconMessage ? 'primary.main' : 'background.paper'}
                color={isCoconMessage ? 'common.white' : 'text.primary'}
            >
                <Typography variant="body1">
                    {message.message}
                </Typography>
            </Box>
        </MessageWrapper>
    );
}

export default MessageBubble;