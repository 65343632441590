import React, { useEffect, } from 'react';
import { connect, ConnectedProps } from "react-redux";
import UserAction from 'actions/user';
import { Error } from 'actions/actions';
import SectionLoader from '../../_include/SectionLoader';
import queryString from 'query-string';
import { usePrevious } from '../../../helpers/refs';
import i18next from 'i18next';
import { Namespaces } from '../../../locales/translations';
import { CONFLICT_ERROR } from '../../../constants/api_errors';
import BackToWebsiteLink from '../../_include/BackToWebsiteLink';
import { Alert, Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';


type VerifyEmailPageProps = {

}

function VerifyEmailPage(props: VerifyEmailPageProps) {

    const userLoading = useAppSelector(state => state.users.loading);
    const userError = useAppSelector(state => state.users.error);

    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    const { t } = useTranslation([Namespaces.snacks]);

    const token = searchParams.get("t");

    useEffect(() => {
        if (token) {
            dispatch(UserAction.verifyEmailAddress(token))
        }
    }, []);

    const wasLoading = usePrevious(userLoading);

    if (!token) {
        return (
            <PageContent>
                <Alert
                    severity="error"
                >
                    {t("invalid_link", { ns: Namespaces.snacks })}
                </Alert>
                <BackToWebsiteLink />
            </PageContent>
        );
    }

    if (wasLoading && !userLoading) { // request to verify email address done
        if (!userError) { // request completed successfully
            return (
                <PageContent>
                    <Alert
                        severity="success"
                    >
                        {t("verify_email.success", { ns: Namespaces.snacks })}
                    </Alert>
                    <BackToWebsiteLink />
                </PageContent>
            );
        }
        else { // something went wrong
            return (
                <PageContent>
                    <Alert
                        severity={userError === CONFLICT_ERROR.body ? "warning" : "error"}
                    >
                        {t(userError, { ns: Namespaces.snacks })}
                    </Alert>
                    <BackToWebsiteLink />
                </PageContent>
            );
        }
    }

    return (
        <PageContent>
            <SectionLoader />
        </PageContent>
    );
}

const PageContent = styled(Container)({
    marginTop: 4,
    maxWidth: 1280,
    margin: "0 auto",
    padding: 4,
    paddingTop: 6,
    textAlign: 'center',
});

export default VerifyEmailPage;