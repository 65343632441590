import { Box, Button, Link, Typography, } from "@mui/material";
import { Namespaces } from "locales/translations";
import Message from "models/Message";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";


interface LinkBubbleProps {
    message: Message;
}

function LinkBubble(props: LinkBubbleProps) {
    const { message } = props;

    const { t } = useTranslation([Namespaces.commons]);

    return (
        <Box sx={{
            paddingBottom: 4,
            paddingLeft: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <Button
                disabled
                color="info"
                variant="contained"
                href={message.attachment?.url}
                component={Link}
                target="_blank"
            >
                {message.message}
            </Button>
            <Typography variant="caption">
                {t("link_only_in_app", { ns: Namespaces.glossary })}
            </Typography>
        </Box>
    );
}

export default LinkBubble;