export default class Stats {
    nbUsers: number;
    nbBatches: number;
    savedCO2: number;
    savedMoney: number;

    public constructor(data: any) {
        this.nbUsers = data.nbUsers;
        this.nbBatches = data.nbBatches;
        this.savedCO2 = data.savedCO2;
        this.savedMoney = data.savedMoney;
    }
}