import { Box } from "@mui/material";
import Rank from "models/Rank";
import React from "react";
import BadgeContent from "./BadgeContent";

interface CurrentRankProps {
    rank: Rank,
}

function CurrentRank(props: CurrentRankProps) {
    const { rank } = props;

    return (
        <BadgeContent>
            <img
                src={rank.getRankImage()}
                style={{
                    width: "100%",
                    height: "100%",
                    position: 'absolute',
                }}
            // resizeMode="cover"
            />
        </BadgeContent>
    )
}

export default CurrentRank;