import Rank, { BadgeRankStatus } from 'models/Rank';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CompletedRank from './CompletedRank';
import CurrentRank from './CurrentRank';
import LockedRank from './LockedRank';

interface RankBadgeProps {
    status: BadgeRankStatus,
    rank: Rank,
    badgeWidth: number | string,
};

function RankBadge(props: RankBadgeProps) {

    const { status, rank, badgeWidth, } = props;

    const getBadge = () => {
        switch (status) {
            case "completed": return <CompletedRank rank={rank} />;

            case "current":
            case "new":
                return <CurrentRank rank={rank} />;

            case "locked": return <LockedRank rank={rank} />;
        }
    };

    const borderWidth = status === "current" || status === "new"
        ? 3
        : status === "completed"
            ? 2
            : 0;

    return (
        <Box
            width={badgeWidth}
            display="flex"
            justifyContent="center"
            sx={{ aspectRatio: "9 / 10" }}
        >
            <Box
                position="relative"
                borderRadius="50%"
                flex={1}
                overflow="hidden"
                bgcolor="white"
                border={borderWidth}
                borderColor='primary.main'
                boxShadow={status !== "current" ? 0 : 4}
                >
                {getBadge()}
            </Box>
        </Box>
    );
};

export default RankBadge;
