import { useState, FormEvent, useEffect } from 'react';
import { Box, Container, Divider, Typography, } from '@mui/material';
import UserAction from 'actions/user';
import ActionButton from 'components/_include/ActionButton';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, } from "firebase/auth";
import { TextInput } from 'helpers/forms';
import PinInputField from './PinInputField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import EmailLoginView from './EmailLogin/EmailLoginView';
import PhoneLoginView from './PhoneLogin/PhoneLoginView';
import ContactFichaButton from './ContactFichaButton';




type LoginPageProps = {

}

function LoginPage({ }: LoginPageProps) {

    const loading = useAppSelector(state => state.users.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.titles]);


    const [inputs, setInputs] = useState<{
        coconCode: TextInput
    }>({
        coconCode: {
            value: "",
            error: null
        },
    });


    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const redirectParam = searchParams.get("next"); // page to navigate to after successful login

    const auth = getAuth();

    // check if was redirected from a magic sign in email link
    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            dispatch(UserAction.logInWithEmailLink(window.location.href));
        }
    }, []);

    // redirect logged in users to home page    
    onAuthStateChanged(auth, (user) => {
        if (user) {
            navigate(redirectParam || "/")
        }
    });

    const getMaskedInputValue = (value: string) => {
        return value.replaceAll(" ", "").replaceAll("_", "");
    };

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }


    const handleSignUpPressed = (event: FormEvent) => {
        event.preventDefault();

        let { coconCode } = inputs;
        let error = false;

        if (!coconCode.value) {
            error = true;
            coconCode.error = "";
        }

        if (error) {
            setInputs({
                ...inputs,
                coconCode: coconCode,
            });
        }
        else {
            dispatch(UserAction.signUp(getMaskedInputValue(coconCode.value)));
        }
    }

    const getPlaceholder = (input: string) => {
        return t(`login.${input}`, { ns: Namespaces.forms });
    }

    const { coconCode } = inputs;

    const signUpFormIsValid = getMaskedInputValue(coconCode.value).length === 6;

    return (
        <Container
            sx={{
                // display: "inline",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <Typography variant="h1" textAlign="center">
                {t("ficha_dashboard", { ns: Namespaces.titles })}
            </Typography>
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                <EmailLoginView />
                <PhoneLoginView />
                <ContactFichaButton />
                <Divider
                    light
                    sx={{
                        my: 4,
                        color: 'text.disabled',
                        width: "100%",
                        maxWidth: 600,
                    }}
                >
                    {t("or", { ns: Namespaces.commons }).toUpperCase()}
                </Divider>
            </Container>

            <form
                method="post"
                action="#"
                style={{ width: "100%", textAlign: "center" }}
            >
                <Box>
                    <PinInputField
                        id="coconCode"
                        name="cocon_code"
                        mask="000000"
                        label={getPlaceholder("cocon_code")}
                        value={coconCode.value}
                        margin="normal"
                        variant="outlined"
                        onChange={(event) => { handleInputChange('coconCode', event.target.value) }}
                        error={Boolean(coconCode.error)}
                        helperText={coconCode.error}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <ActionButton
                    color="gradient"
                    disabled={!signUpFormIsValid}
                    loading={loading}
                    onClick={(event) => handleSignUpPressed(event)}
                    type="submit"
                >
                    {t("sign_up", { ns: Namespaces.actions })}
                </ActionButton>
            </form>

        </Container>
    )
}

export default LoginPage;