export type Contact = {
    name: string;
    role: string;
    email: string;
    phone?: string;
}

export const CONTACTS: { [company: string]: Contact[]} = {
    "Ficha": [
        {
            name: "Vincent Hipault",
            role: "CEO",
            email: "vincent.hipault@ficha.fr",
        },
        {
            name: "Guillaume Dionisi",
            role: "Développeur",
            email: "guillaume.dionisi@ficha.fr",
        },
    ],
};