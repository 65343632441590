import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IconAndTextVertical from './IconAndTextVertical';
import { connect } from 'react-redux';
import { Namespaces } from 'locales/translations';
import Stats from 'models/Stats';
import { Box, Divider, Grid, Typography } from '@mui/material';
import urls from 'constants/urls';
import StatsAction from 'actions/stats';
import { styled } from '@mui/system';
import SectionDivider from 'components/_include/SectionDivider';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const StatsColumn = styled(Grid)({
    textAlign: "center",
});

interface CommunityStatsProps {

}

function CommunityStats(props: CommunityStatsProps) {


    const communityStats = useAppSelector(state => state.stats.stats);
    const nbBatches = useAppSelector(state => state.stats.stats?.nbBatches);
    const nbUsers = useAppSelector(state => state.stats.stats?.nbUsers);
    const savedMoney = useAppSelector(state => state.stats.stats?.savedMoney);
    const dispatch = useAppDispatch();


    const { t } = useTranslation([Namespaces.glossary,]);

    useEffect(() => {
        if (!communityStats) {
            dispatch(StatsAction.getCommunityStats());
        }
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <SectionDivider />

            <Typography
                variant="h4"
                textAlign="center"
                mx={2}
                marginBottom={3}
            >
                {t("ficha_community", { ns: Namespaces.glossary })}
            </Typography>

            <Grid container>
                <StatsColumn item xs={4}>
                    <IconAndTextVertical
                        icon={`${urls.IMAGES_FOLDER}/community/recycle.png`}
                        iconSize={48}
                        text={t(`sorting`, {
                            ns: Namespaces.glossary,
                            count: nbBatches,
                            context: nbBatches ? "" : "unknown"
                        })}
                    />
                </StatsColumn>
                <StatsColumn item xs={4}>
                    <IconAndTextVertical
                        icon={`${urls.IMAGES_FOLDER}/community/users_round.png`}
                        iconSize={48}
                        text={t(`member`, {
                            ns: Namespaces.glossary,
                            count: nbUsers,
                            context: nbUsers ? "" : "unknown"
                        })}
                    />
                </StatsColumn>
                <StatsColumn item xs={4}>
                    <IconAndTextVertical
                        icon={`${urls.IMAGES_FOLDER}/community/present_round.png`}
                        iconSize={48}
                        text={t(`rewards_earned`, {
                            ns: Namespaces.glossary,
                            count: savedMoney,
                            context: savedMoney ? "" : "unknown"
                        })}
                    />
                </StatsColumn>
            </Grid>

            <SectionDivider />
        </Box>
    );

};

export default CommunityStats;