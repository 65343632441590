import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Reward, { RewardFilter, RewardsCategories, RewardsFilters } from "../../../models/Shop/Reward";
import { LoadableContext, RootState } from "../../store";

type FilteredRewardsContext = {
    filters: RewardsFilters,
    data: Reward[],
    loading: boolean,
    totalCount: number;
};

const initialState: FilteredRewardsContext = {
    loading: false,
    data: [],
    filters: {
        [RewardFilter.CATEGORY]: Object.values(RewardsCategories),
        [RewardFilter.LOCATION]: {
            selected: false,
        },
        [RewardFilter.FAVORITES]: false,
    },
    totalCount: 0,
};


export const filteredRewardsSlice = createSlice({
    name: 'rewards_filtered_list',
    initialState: initialState,
    reducers: {
        startLoading: (state, { payload: filters }: PayloadAction<RewardsFilters>) => {
            state.loading = true;
            state.filters = filters;
            state.totalCount = 0;
        },
        setData: (state, { payload: { rewards, totalCount } }: PayloadAction<{ rewards: Reward[], totalCount?: number }>) => {
            state.loading = false;
            state.data = rewards;
            if (totalCount !== undefined) state.totalCount = totalCount;
        },
        resetFilters: (state, { payload: filters }: PayloadAction<RewardsFilters>) => {
            state.filters = filters;
        },
    },
});

export const FilteredRewardsActions = filteredRewardsSlice.actions;

const FilteredRewardsReducer = filteredRewardsSlice.reducer;

export default FilteredRewardsReducer;