import { UNAUTHORIZED_ERROR } from "constants/api_errors";
import { getAuth } from "firebase/auth";


export interface Error {
    code: number;
    body: any;
}

export async function fetchAPI(input: RequestInfo, init?: RequestInit): Promise<any> {
    // retrieve API token
    const auth = getAuth();
    const user = auth.currentUser;

    let token = await user?.getIdToken();
    if (!token) {
        throw UNAUTHORIZED_ERROR;
    }
    else {
        let headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            ...init?.headers,
        };

        try {
            const res = await fetch(input, {
                ...init,
                headers: headers,
            });
            return await handleAPIResponse(res);
        }
        catch (e) {
            // do common error handling here
            throw e;
        };
    }
}

/**
 * Format body of the response to be readable.
 * Throw an Error to be caught and handled in reducers upon API errors (e.g. 400, 404...)
 * @param res The Response of the request to the API
 */
export const handleAPIResponse = async (res: Response) => {
    try {
        const json = await res.json();
        if (!res.ok) {
            throw {
                code: res.status,
                body: json,
            };
        }
        return json;
    }
    catch (e) { // failed parsing json
        const error = (e as Error)
        console.error(error);
        throw {
            code: res.status,
            body: error.body || "",
        }
    }
};