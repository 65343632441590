import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Reward, { RewardFilter, RewardsCategories, RewardsFilters } from "../../../models/Shop/Reward";
import { LoadableContext, RootState } from "../../store";

const rewardsAdapter = createEntityAdapter<Reward>({
    selectId: reward => reward.id,
});

type RewardsContext = LoadableContext & {
    highlighted: Reward | null,
    requestId: number | null,
    next?: string;
};

const initialState: RewardsContext = {
    loading: false,
    error: null,
    highlighted: null,
    requestId: null,
};


export const rewardsSlice = createSlice({
    name: 'rewards_list',
    initialState: rewardsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state, { payload: requestId }: PayloadAction<number>) => {
            rewardsAdapter.removeAll(state); // clear all loaded rewards (eg change of selected partner)
            state.loading = true;
            state.error = null;
            state.highlighted = null;
            state.next = undefined;
            state.requestId = requestId;
        },
        setRequestId: (state, { payload: requestId }: PayloadAction<number | null>) => {
            state.requestId = requestId;
        },
        setNext: (state, { payload: next }: PayloadAction<string | undefined>) => {
            state.next = next;
        },
        setList: (state, { payload: { rewards, next } }: PayloadAction<{ rewards: Reward[], next: string | undefined }>) => {
            state.loading = false;

            let rewardsList = rewards;
            // highlight first element if it has an expiry date
            if (rewards.length > 0 && rewards[0].expiryDate) {
                state.highlighted = rewards[0];
                rewardsList = rewards.slice(1);
            }

            rewardsAdapter.setAll(state, rewardsList);

            state.next = next;
            state.requestId = null;
        },
        addToList: (state, { payload: { rewards, next } }: PayloadAction<{ rewards: Reward[], next: string | undefined }>) => {
            state.loading = false;
            rewardsAdapter.addMany(state, rewards);
            state.next = next;
        },
        updateItem: (state, { payload: { rewardId, data } }: PayloadAction<{ rewardId: string, data: Partial<Reward> }>) => {
            rewardsAdapter.updateOne(state, {
                id: rewardId,
                changes: data,
            });
        },
        decrementItemCount: (state, { payload: rewardId }: PayloadAction<string>) => {
            const reward = state.entities[rewardId];
            console.debug(rewardId, "rewardId");
            console.debug(reward, "reward");
            if (reward?.quantity) { // reward has a limited quantity
                if (reward?.quantity > 1) { // reward still available
                    rewardsAdapter.updateOne(state, {
                        id: rewardId,
                        changes: {
                            quantity: reward.quantity - 1,
                        },
                    });
                }
                else { // last available just purchased, remove reward
                    rewardsAdapter.removeOne(state, rewardId);
                }
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const RewardsActions = rewardsSlice.actions;


export const {
    selectAll: selectAllRewards,
    selectById: selectRewardById,
    selectIds: selectRewardsIds
} = rewardsAdapter.getSelectors((state: RootState) => state.rewards.list)

const RewardsReducer = rewardsSlice.reducer;

export default RewardsReducer;