import { Box, Button, Container, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Namespaces } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmailIcon from '@mui/icons-material/Email';


export default function ContactFichaButton() {
    const [dialogOpen, setDialogOpen] = useState(false);

    const { t } = useTranslation([Namespaces.user]);

    const handleSendEmail = () => {
        window.location.href = "mailto:guillaume.dionisi@ficha.fr";
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    onClick={() => setDialogOpen(true)}
                    size='large'
                    color="warning"
                >
                    {t("trouble_signing_in", { ns: Namespaces.user })}
                </Button>
            </Box>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>{t("trouble_signing_in", { ns: Namespaces.user })}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="contained"
                            onClick={handleSendEmail}
                            endIcon={<EmailIcon />}
                            color="warning"
                        >
                            Contacter le support
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Container>
    );
}