import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Purchase from "../../../models/Shop/Purchase";
import { LoadableContext, RootState } from "../../store";

export enum PurchaseStep {
    INIT,
    COMPLETE_PROFILE,
    LOADING,
    DONE,
}

type selectedPurchaseContext = LoadableContext & {
    data: Purchase | null,
    step: PurchaseStep;
};

const initialState: selectedPurchaseContext = {
    data: null,
    loading: false,
    error: null,
    step: PurchaseStep.INIT,
};


export const selectedPurchaseSlice = createSlice({
    name: 'selected_purchase',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
            state.step = PurchaseStep.LOADING;
        },
        set: (state, { payload: purchase }: PayloadAction<Purchase | null>) => {
            state.loading = false;
            state.data = purchase;
            if (purchase) { // purchase complete
                state.step = PurchaseStep.DONE;
            }
            else { // reset purchase after completed
                state.step = PurchaseStep.INIT;
            }
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        setStep: (state, { payload: step }: PayloadAction<PurchaseStep>) => {
            state.step = step;
        },
    },
});

export const SelectedPurchaseActions = selectedPurchaseSlice.actions;

const SelectedPurchaseReducer = selectedPurchaseSlice.reducer;

export default SelectedPurchaseReducer;