import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';

interface IconAndTextProps {
    icon: string,
    iconSize: number,
    text: string,
};

function IconAndTextVertical(props: IconAndTextProps) {

    const { icon, iconSize, text } = props;

    return (
        <Box 
            alignItems="center"
            justifyContent="flex-start"
            flex={1}  
            >
            <img
                style={{
                    width: iconSize,
                    height: iconSize,
                }}
                src={icon} />
            <Box
                justifyContent="center"
                flex={1}
            >
                <Typography 
                    variant="caption" 
                    marginTop={1}
                    fontSize={14}
                    textAlign="center"
                    >
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

export default IconAndTextVertical;
