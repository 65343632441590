import { User as FirebaseUser } from "firebase/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataContext, LoadableContext } from "store/store";
import User from "models/User";



type UserContext = LoadableContext & {
    data: User | null;
    loaded: boolean;
}

const initialState: UserContext = {
    data: null,
    loading: false,
    error: null,
    loaded: false,
};



export const usersSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.loaded = false;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
            state.loading = false;
        },
        update: (state, { payload: data }: PayloadAction<Partial<User>>) => {
            state.loading = false;
            state.data = {
                ...state.data!,
                ...data,
            };
        },
        setProfile: (state, { payload: user }: PayloadAction<User>) => {
            state.data = user;
            state.loading = false;
            state.loaded = true;
        },
        setSignedUp: (state) => {
            state.loading = false;
            state.error = null;
        },
        setSignedIn: (state) => {
            state.loading = false;
            state.error = null;
        },
        addToScore: (state, { payload: points }: PayloadAction<number>) => {
            if (state.data) state.data.score += points;
        },
        resetPasswordSuccess: (state) => {
            state.error = null;
            state.loading = false;
        },
        decreaseBalance: (state, { payload: points }: PayloadAction<number>) => {
            if (state.data) state.data.balance -= points;
        },
        setSignOut: (state) => {
            state.data = null;
            state.loading = false;
            state.error = null;
        },
        completeProfileSuccess: (state) => {
            state.loading = false;
            state.error = null;
        },
        setLoaded: (state, { payload: loaded }: PayloadAction<boolean>) => {
            state.loaded = loaded;
        },
    },
})

export const UserActions = usersSlice.actions;

const UsersReducer = usersSlice.reducer;

export default UsersReducer;