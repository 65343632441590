import { Address } from "helpers/types";
import { Locales } from "locales/translations";
import moment from "moment";
import Cocon, { CoconData } from "./Cocon";
import Rank, { RankData } from "./Rank";

export enum UserRoles {
    COCON_USER = "cocon_user",
    ADMIN = "admin",
}

export interface UserData {
    id: string;
    email: string;
    phone?: string;
    firstName: string;
    lastName: string;
    address?: Address;
    score: number;
    balance: number;
    pin?: number;
    password: string;
    role: UserRoles;
    rank: RankData;
    savedCO2: number;
    savedMoney: number;
    cocon?: CoconData;
    favoriteRewards: string[];
    locale: Locales;
}

export type CompleteProfileData = Pick<User, "firstName" | "lastName" | "email" | "address">;

export default class User {
    id: string;
    email: string;
    phone?: string;
    firstName: string;
    lastName: string;
    address?: Address;
    score: number;
    balance: number;
    pin?: number;
    password: string;
    role: UserRoles;
    rank: Rank;
    savedCO2: number;
    savedMoney: number;
    cocon?: Cocon;
    favoriteRewards: string[];
    locale: Locales;

    public constructor(data: UserData) {
        this.id = data.id;
        this.email = data.email;
        this.phone = data.phone;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.address = data.address;
        this.score = data.score;
        this.balance = data.balance;
        this.pin = data.pin;
        this.password = data.password;
        this.role = data.role;
        this.rank = new Rank(data.rank);
        this.savedCO2 = data.savedCO2;
        this.savedMoney = data.savedMoney;
        this.cocon = data.cocon ? new Cocon(data.cocon) : undefined;
        this.favoriteRewards = data.favoriteRewards;
        this.locale = data.locale;
    }

    public data(fields?: string[]): UserData {
        let data: any = {
            id: this.id,
            email: this.email,
            phone: this.phone,
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.address,
            score: this.score,
            balance: this.balance,
            pin: this.pin,
            password: this.password,
            rank: this.rank.data(),
            role: this.role,
            savedCO2: this.savedCO2,
            savedMoney: this.savedMoney,
            cocon: this.cocon ? this.cocon.data() : undefined,
            favoriteRewards: this.favoriteRewards,
            locale: this.locale,
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    }

    public getPin() {
        return this.pin?.toString().padStart(4, '0');
    };
}