import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useAppDispatch, useAppSelector } from "store/hooks";
import UserAction from "actions/user";
import { formatPhoneNumber } from "helpers/strings";
import { ConfirmationResult } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { LoadingButton } from "@mui/lab";

type PhoneLoginViewProps = {

}

function PhoneLoginView(props: PhoneLoginViewProps) {
    const [openSMSDialog, setOpenSMSDialog] = useState(false);
    // const [resendDisabled, setResendDisabled] = useState(false);
    // const [resendTimer, setResendTimer] = useState(60); // Timer in seconds

    const handleClickOpenSMSDialog = () => {
        setOpenSMSDialog(true);
    };

    const loading = useAppSelector(state => state.users.loading);

    const { t } = useTranslation([Namespaces.actions]);

    const dispatch = useAppDispatch();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [confirmation, setConfirmation] = useState<null | ConfirmationResult>();
    const [errorMessage, setErrorMessage] = useState<"">();

    const [incorrectCodeVisible, setIncorrectCodeVisible] = useState(false);

    // const handleResendSmsPressed = () => {
    //     console.log("resend sms pressed")
    //     dispatch(UserAction.resendSignInSMS(formatPhoneNumber(phoneNumber)))
    //         .then(result => {
    //             if (result) { // sms successfully sent
    //                 console.log("sms sent", result)
    //                 setConfirmation(result);
    //                 // setResendDisabled(true);
    //                 // startResendTimer();
    //             }
    //             else {
    //                 console.log("error sending sms")
    //             }
    //         });
    // }

    const handleSendSmsPressed = () => {
        console.log("send sms pressed")
        dispatch(UserAction.sendSignInSMS(formatPhoneNumber(phoneNumber)))
            .then(result => {
                if (result) { // sms successfully sent
                    setConfirmation(result);
                    // setResendDisabled(true);
                    // startResendTimer();
                }
                else {
                    console.log("error sending sms")

                }
            });
    };

    const handleConfirmCodePressed = () => {
        if (confirmation) {
            dispatch(UserAction.logInWithPhoneNumber(confirmation, verificationCode))
                .then(correctCode => {
                    console.log("correct code", correctCode)
                    if (!correctCode) { // incorrect code
                        setIncorrectCodeVisible(true);
                    }
                })
                .catch(error => {
                    console.log("FirebaseError:", error);
                    const errorMessage = error.message || "An error occurred.";
                    setErrorMessage(errorMessage);
                });
        }
    };

    const handleCloseSMSDialog = () => {
        setOpenSMSDialog(false);
    }

    // const startResendTimer = () => {
    //     let timer = setInterval(() => {
    //         setResendTimer(prevTimer => prevTimer - 1);
    //     }, 1000);

    //     // Clear the timer after 60 seconds
    //     setTimeout(() => {
    //         clearInterval(timer);
    //         setResendDisabled(false); // Enable the resend button
    //         setResendTimer(60); // Reset the timer value
    //     }, 60000);
    // };

    // useEffect(() => {
    //     if (resendTimer === 0) {
    //         setResendDisabled(false); // Enable the resend button
    //     }
    // }, [resendTimer]);

    return (
        <Container >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    endIcon={<PhoneIphoneIcon />}
                    size='large'
                    onClick={handleClickOpenSMSDialog}
                >
                    {t("sms_connect", { ns: Namespaces.actions })}
                </Button>
            </Box>
            <Dialog open={openSMSDialog} onClose={handleCloseSMSDialog}>
                <DialogTitle> {t("send_sms_code", { ns: Namespaces.titles })}</DialogTitle>
                <DialogContent>
                    {!confirmation ? (
                        <Container><TextField
                            autoFocus
                            margin="normal"
                            id="numéro"
                            label="Numéro de téléphone"
                            type="tel"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+33</InputAdornment>,
                            }}
                        />
                            <div id="recaptcha-container"></div>
                        </Container>) : (
                        <Container>
                            <TextField
                                autoFocus
                                margin="normal"
                                id="code"
                                label="Code de vérification"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    setVerificationCode(e.target.value)
                                    if (incorrectCodeVisible)
                                        setIncorrectCodeVisible(false);
                                }}
                                value={verificationCode}
                                error={incorrectCodeVisible}
                            />
                            {incorrectCodeVisible && (
                                <FormHelperText error>
                                    {t("incorrect_code_error", { ns: Namespaces.actions })}
                                </FormHelperText>
                            )}

                            {/* <Grid container spacing={2} direction={"row"}>
                                <Grid item xs={8}>
                                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
                                        {t("sign_in_sms.code_not_received", { ns: Namespaces.snacks })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={handleResendSmsPressed} variant="text" color="info" size="small" disabled={true} id="resend-button" >
                                        {t("sign_in_sms.resend", { ns: Namespaces.snacks })}
                                    </Button>
                                </Grid>
                            </Grid> */}
                        </Container>
                    )}

                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: 3 }} >
                    {!confirmation ?
                        <LoadingButton onClick={handleSendSmsPressed} variant="contained" color="success" loading={loading}>
                            {t("send", { ns: Namespaces.actions })}
                        </LoadingButton> :
                        <LoadingButton onClick={handleConfirmCodePressed} variant="contained" color="success" loading={loading}>
                            {t("confirm", { ns: Namespaces.actions })}
                        </LoadingButton>
                    }
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default PhoneLoginView;