import { Coordinates } from "helpers/geo";
import moment from "moment";

export enum CoconStatus {
    DEV = "dev",
    PREPARING = "preparing",
    DEPLOYED = "deployed",
}

export interface CoconData {
    id?: string;
    address: string;
    postcode: string;
    city: string;
    coordinates?: Coordinates;
}

export default class Cocon {
    id: string;
    address: string;
    postcode: string;
    city: string;
    coordinates: Coordinates;

    constructor(data: any) {
        this.id = data.id;
        this.address = data.address;
        this.postcode = data.postcode;
        this.city = data.city;
        this.coordinates = data.coordinates;
    }

    public data(fields?: string[]): CoconData {
        let data: any = {
            id: this.id,
            address: this.address,
            postcode: this.postcode,
            city: this.city,
            coordinates: this.coordinates,
        };

        if (fields) {
            Object.keys(data).forEach(field => {
                if (fields.indexOf(field) < 0) { // field not in fields to return
                    delete data[field];
                }
            });
        }

        return data;
    }

    getAddress = () => {
        return `${this.address}, ${this.postcode} ${this.city}`;
    }
}