import moment from "moment";

export enum MessageAttachmentType {
  IMAGE = "image",
  VIDEO = "video",
  LINK = "link",
  INTERNAL_LINK = "internal_link",
}

export interface MessageAttachment {
  type: MessageAttachmentType;
  url: string;
}

export interface MessageData {
  timestamp: number;
  from: string;
  message: string;
  attachment?: MessageAttachment;
  conversationId?: string;
}

export default class Message {
  timestamp: moment.Moment;
  from: string;
  message: string;
  attachment?: MessageAttachment;
  conversationId?: string;

  constructor(data: MessageData) {
    this.timestamp = moment(data.timestamp, 'x');
    this.from = data.from;
    this.message = data.message;
    this.attachment = data.attachment;
    this.conversationId = data.conversationId;
  }

  public data(fields?: string[]): MessageData {
    let data: any = {
      timestamp: this.timestamp,
      from: this.from,
      message: this.message,
      attachment: this.attachment,
      conversationId: this.conversationId,
    };

    if (fields) {
      Object.keys(data).forEach(field => {
        if (fields.indexOf(field) < 0) { // field not in fields to return
          delete data[field];
        }
      });
    }

    return data;
  }

  getAttachmentVideo = () => {
    if (!this.attachment) return null; // no attachment

    // youtube
    let youtubeMatch = this.attachment.url.match(/https:\/\/www\.youtube\.com\/watch\?v=(.*)/);
    if (!youtubeMatch) {
      youtubeMatch = this.attachment.url.match(/https:\/\/youtu\.be\/(.*)/);
    }
    if (youtubeMatch) {
      return {
        platform: "youtube",
        videoId: youtubeMatch[1],
      }
    }

    // vimeo
    const vimeoMatch = this.attachment.url.match(/https:\/\/player\.vimeo\.com\/video\/(.*)/);
    if (vimeoMatch) {
      return {
        platform: "vimeo",
        videoId: vimeoMatch[1],
      }
    }

    return null; // not found
  }
}