import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Message from "../../../models/Message";
import { send } from "process";

type SendingMessageContext = {
    data: Message | null,
    loading: boolean,
    error: Error | null,
}

const initialState: SendingMessageContext = {
    data: null,
    loading: false,
    error: null,
}

const sendingMessageSlice = createSlice({
    name: 'sending_message',
    initialState: initialState,
    reducers: {
        StartSendingMessage: (state, { payload: message }: PayloadAction<Message>) => {
            state.loading = true;
            state.data = message;
            state.error = null;
        },
        finishSendingMessage: (state) => {
            state.data = null;
            state.loading = false;
        },
        setError: (state, action: PayloadAction<Error>) => {
            state.loading = false;
            state.error = action.payload;
            state.data = null;
        }

    }
})

export const SendingMessageActions = sendingMessageSlice.actions;

const SendingMessageReducer = sendingMessageSlice.reducer;

export default SendingMessageReducer;