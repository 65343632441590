import { configureStore, } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import ActionResultReducer from './reducers/actions_result';
import MessageReducer from './reducers/messages/list';
import SendingMessageReducer from './reducers/messages/sending_message';
import RewardsReducer from './reducers/rewards/list';
import StatsReducer from './reducers/stats';
import UserReducer from './reducers/user';
import RewardsFiltersReducer from './reducers/rewards/filters';
import FilteredRewardsReducer from './reducers/rewards/filtered_list';
import SelectedRewardReducer from './reducers/rewards/selected';
import PurchasesReducer from './reducers/purchases/list';
import PastPurchasesReducer from './reducers/purchases/past';
import SelectedPurchaseReducer from './reducers/purchases/selected';

const RewardsCombinedReducer = combineReducers({
    list: RewardsReducer,
    filters: RewardsFiltersReducer,
    filtered_list: FilteredRewardsReducer,
    selected: SelectedRewardReducer,
})

const MessagesCombinedReducer = combineReducers({
    list: MessageReducer,
    sendingMessage: SendingMessageReducer,
});

const PurchasesCombinedReducer = combineReducers({
    list: PurchasesReducer,
    past: PastPurchasesReducer,
    selected: SelectedPurchaseReducer,
})


const store = configureStore({
    reducer: {
        actionResult: ActionResultReducer,
        messages: MessagesCombinedReducer,
        rewards: RewardsCombinedReducer,
        stats: StatsReducer,
        users: UserReducer,
        purchases: PurchasesCombinedReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // HOTFIX: do not enforce serialization of data
        immutableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type LoadableContext = {
    loading: boolean;
    error: string | null;
}

export type DataContext<T> = LoadableContext & {
    data: T;
} 
