import { Input, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

type PinInputFieldProps = TextFieldProps & {
    name: string;
    value: string | number | string[] | undefined;
    mask: string;
    
}

type CustomProps = PinInputFieldProps & {
    onChange: (event: { target: { name: string; value: string } }) => void;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <IMaskInput
                {...other}
                definitions={{
                    '#': /[1-9]/,
                }}
                lazy={false}
                placeholderChar="_"
                style={{
                    letterSpacing: 6,
                }}
                inputRef={ref}
                onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export default function PinInputField(props: PinInputFieldProps) {

    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: TextMaskCustom as any,
                inputProps: {
                    mask: props.mask,
                },
            }}
        />
    );
}