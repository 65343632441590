import { Box } from "@mui/material";
import Rank from "models/Rank";
import React from "react";
import BadgeContent from "./BadgeContent";
import LockIcon from '@mui/icons-material/Lock';

interface LockedRankProps {
    rank: Rank,
}

function LockedRank(props: LockedRankProps) {
    const { rank } = props;

    return (
        <BadgeContent>
            <img
                src={rank.getRankImage()}
                style={{
                    width: "100%",
                    height: "100%",
                    position: 'absolute',
                }}
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgcolor='#E0E0E0'
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <LockIcon color="disabled" />
            </Box>
        </BadgeContent>
    )
}

export default LockedRank;