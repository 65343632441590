import { Box, Typography, } from "@mui/material";
import Message, { MessageAttachmentType } from "models/Message";
import React from "react";
import { connect } from "react-redux";
import YouTube from 'react-youtube';
import { makeStyles } from '@mui/styles';
import MessageWrapper from "./MessageWrapper";


type MediaBubbleProps = {
    message: Message;
}

function MediaBubble(props: MediaBubbleProps) {
    const { message } = props;

    const classes = useStyles();

    const isCoconMessage = message.from !== "user";

    const getMedia = () => {
        if (!message.attachment) return undefined;

        // attachment can be image or video URL
        switch (message.attachment.type) {
            case MessageAttachmentType.IMAGE:
                return (
                    <img
                        src={message.attachment.url}
                        className={classes.mediaWrapper}
                    />
                );

            case MessageAttachmentType.VIDEO:
                const attachmentVideo = message.getAttachmentVideo();
                if (attachmentVideo && attachmentVideo.platform === "youtube") {
                    return (
                        <YouTube
                            videoId={attachmentVideo.videoId}
                            className={classes.mediaWrapper}
                        />
                    );
                }
                break;
        }

        return undefined;
    }

    return (
        <MessageWrapper isCoconMessage={isCoconMessage}>
            <Box sx={{
                borderRadius: 4,
                boxShadow: 7,
                backgroundColor: 'primary.main',
                color: "white",
                overflow: "hidden",
            }}>
                <Typography
                    variant="body1"
                    sx={{
                        px: 2,
                        py: 2,
                    }}
                >
                    {message.message}
                </Typography>
                {getMedia()}
            </Box>
        </MessageWrapper>
    );
}

const useStyles = makeStyles({
    mediaWrapper: {
        marginBottom: -10,
        width: "100%"
    },
});

export default MediaBubble;