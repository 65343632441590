const serverURL = process.env.REACT_APP_SERVER_URL;
const googleMapsWebsite = "https://www.google.com/maps";
const dashboardURL = process.env.REACT_APP_DASHBOARD_URL;

export default {
    SERVER: serverURL,
    API: {
        V3: `${serverURL}/api/v3`,
        V4: `${serverURL}/api/v4`,
    },
    STORAGE: "https://storage.googleapis.com",
    WEBSITE: "https://ficha.fr",
    WEBAPP: "https://app.ficha.fr",
    GOOGLE_REFRESH_TOKEN_API: "https://securetoken.googleapis.com/v1/token",
    GOOGLE_MAPS: {
        WEBSITE: googleMapsWebsite,
        PLACE: `${googleMapsWebsite}/place`,
    },
    FEEDBACK_FORM: "https://docs.google.com/forms/d/e/1FAIpQLSeglxyzUUVw4Gq_jQIE8DqDBVxYvWemSPGqyyZbRG0Ru15yOA/viewform",
    COMPLETE_ADDRESS_FORM: `${dashboardURL}/complete-profile/`,
    MOBILE_APP_LINKS: {
        FACEBOOK_PAGE: "{{facebookScheme}}/160694767892666",
    },
    IMAGES_FOLDER: `${process.env.PUBLIC_URL}/images`,
}