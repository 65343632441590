import { fetchAPI } from 'actions/actions';
import Reward, { RewardFilter, RewardsCategories, RewardsFilters } from 'models/Shop/Reward';
import queryString from 'query-string';
import { QueryFilters } from 'helpers/types';
import moment from 'moment';
import store, { AppDispatch } from '../store/store';
import urls from 'constants/urls';
import Purchase from 'models/Shop/Purchase';
import { FilteredRewardsActions } from 'store/reducers/rewards/filtered_list';
import { RewardsActions } from 'store/reducers/rewards/list';
import { RewardsFiltersActions } from 'store/reducers/rewards/filters';
import { SelectedRewardActions } from 'store/reducers/rewards/selected';


interface RewardsList {
  results: Reward[];
  count: number;
  next?: string;
}

const listRewards = (filters: RewardsFilters, previewFilters: boolean) => async (dispatch: AppDispatch) => {

  // track request timestamp to only display results of the last one
  // as many request may happen at the same time when users play with filters
  const requestId = Date.now();

  if (previewFilters) { // load "matching" rewards count on filters screen
    dispatch(FilteredRewardsActions.startLoading(filters));
    dispatch(RewardsActions.setRequestId(requestId));
  }
  else { // load rewards on shop screen
    dispatch(RewardsActions.startLoadingList(requestId));
    dispatch(RewardsFiltersActions.setFilters(filters));
    dispatch(FilteredRewardsActions.resetFilters(filters));
  }

  // format query params depending on filters
  const queryFilters: QueryFilters = {
    quantity: [">", 0],
    orderBy: "random",
  };
  Object.entries(filters).forEach(([filter, value]) => {
    if (value !== undefined) {
      switch (filter as RewardFilter) {
        case RewardFilter.AFFORDABLE:
          //ignore
          break;

        case RewardFilter.NEW:
          if (value == true)
            queryFilters.dateAdded = [">=", Number(moment().subtract('30', 'days').format('x'))];
          break;

        case RewardFilter.LOCATION:
          if (filters[RewardFilter.LOCATION].selected && filters[RewardFilter.CATEGORY].indexOf(RewardsCategories.PHYSICAL) >= 0) {
            // only take distance into account if physical rewards selected 
            queryFilters.latitude = value.latitude;
            queryFilters.longitude = value.longitude;
            queryFilters.radius = value.radius;
          }
          break;

        case RewardFilter.FAVORITES:
          if (value)
            queryFilters[filter] = value;
          break;

        default:
          queryFilters[filter] = value;
          break;
      }
    }
  });

  const url = `${urls.API.V4}/reward?${queryString.stringify(queryFilters)}`;

  console.debug("loading rewards", url);

  try {
    const { results: rewards, next, count }: RewardsList = await fetchAPI(url, {
      method: 'GET',
    });

    const latestRequestId = store.getState().rewards.list.requestId;
    if (!latestRequestId || requestId < latestRequestId) { // there is a more recent request
      // do not dispatch results of this request
      return;
    }

    if (previewFilters) { // load "matching" rewards count on filters screen
      dispatch(FilteredRewardsActions.setData({ rewards, totalCount: count }));
      dispatch(RewardsActions.setRequestId(null));
      dispatch(RewardsActions.setNext(next));
    }
    else { // load rewards on shop screen
      dispatch(RewardsActions.setList({ rewards, next }));
      dispatch(FilteredRewardsActions.setData({ rewards, totalCount: count }));
    }

    return rewards;
  }
  catch (e) {
    const error = e as Error;
    console.error("Failed loading rewards:", error);
    dispatch(RewardsActions.setError(error.message));

    return [];
  }
}

const loadMoreRewards = (queryFilters: string) => async (dispatch: AppDispatch) => {

  const url = `${urls.API.V4}/reward${queryFilters}`;

  console.debug("loading more rewards", url);

  try {
    const { results: rewards, next, }: RewardsList = await fetchAPI(url, {
      method: 'GET',
    });

    dispatch(RewardsActions.addToList({ rewards, next }));

    return rewards;
  }
  catch (e) {
    const error = e as Error;
    console.debug("Failed loading more rewards:", error);
    dispatch(RewardsActions.setError(error.message));

    return [];
  }
}

const reloadRewards = () => (dispatch: AppDispatch) => {
  // reload rewards list
  const { filters } = store.getState().rewards;
  dispatch(listRewards(filters.list, false));
}


const applyFilters = () => (dispatch: AppDispatch) => {
  const rewardsState = store.getState().rewards;
  console.debug("rewardsState", rewardsState);
  dispatch(RewardsActions.setList({ rewards: rewardsState.filtered_list.data, next: rewardsState.list.next }));
  dispatch(RewardsFiltersActions.setFilters(rewardsState.filtered_list.filters));
};

const resetFilters = () => (dispatch: AppDispatch) => {
  const rewardsState = store.getState().rewards;
  dispatch(FilteredRewardsActions.resetFilters(rewardsState.filters.list));

  const rewards = Object.values(rewardsState.list.entities) as Reward[];
  dispatch(FilteredRewardsActions.setData({ rewards: rewards }));
};

const purchaseReward = (rewardId: string) => async (dispatch: AppDispatch) => {
  dispatch(SelectedRewardActions.set(null));

  const user = store.getState().users.data;
  if (!user) return;

  const url = `${urls.API.V3}/user/${user.id}/purchase`;

  try {
    const response = await fetchAPI(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        rewardId: rewardId
      })
    });
    const body = await response.json();

    dispatch(SelectedRewardActions.set(body.purchase)); // Need to check this 

  } catch (error) {
    dispatch(SelectedRewardActions.setError(error as string));
    alert("Oups, quelque chose n'a pas fonctionné... Réessaye un peu plus tard!");
    return null
  }



};

const RewardsAction = {
  listRewards,
  loadMoreRewards,
  reloadRewards,
  applyFilters,
  resetFilters,
  purchaseReward,
};

export default RewardsAction;