import React, { ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppBar, SvgIconTypeMap, Tabs, Toolbar, Typography, useMediaQuery, } from '@mui/material';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ChatIcon from '@mui/icons-material/Chat';
import { History } from 'history';
import UserAction from 'actions/user';
import User from 'models/User';
import { useTheme } from '@mui/material/styles';
import AppBarTab from './AppBarTab';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';



type WebAppBarProps = {

}

type Route = {
    url: string;
    label: string;
    icon: ReactElement;
}

function WebAppBar(props: WebAppBarProps) {

    const user = useAppSelector(state => state.users.data);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.titles]);

    const location = useLocation();

    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const routes: Route[] = [{
        url: "/",
        label: isUpMd ? t("profile", { ns: Namespaces.titles }) : "",
        icon: <PersonIcon />,
    }, {
        url: "/shop",
        label: isUpMd ? t("shop", { ns: Namespaces.titles }) : "",
        icon: <StorefrontIcon />,
    }, {
        url: "/chat",
        label: isUpMd ? t("cocon", { ns: Namespaces.titles }) : "",
        icon: <ChatIcon />,
    }];

    const getTabIndex = (url: string) => routes.findIndex(r => r.url === url);

    const [currentTab, setCurrentTab] = useState<number>(getTabIndex(location.pathname));

    useEffect(() => {
        if (!user) {
            dispatch(UserAction.getProfile());
        }
    }, []);

    useEffect(() => {
        setCurrentTab(getTabIndex(location.pathname));
    }, [location]);

    return (
        <AppBar
            position="sticky"
            color="secondary"
            sx={{
                height: (theme) => theme.spacing(10),
                flexDirection: "row",
                justifyContent: "space-between",
                px: 6,
                [theme.breakpoints.down('md')]: {
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                },
            }}
        >
            <Toolbar>
                <Typography variant="h4" sx={{
                    textTransform: "uppercase",
                    textShadow: `${theme.palette.primary.main} 2px 4px`,
                    marginRight: 4,
                    [theme.breakpoints.down('md')]: {
                        display: "none",
                    }
                }}>
                    {t("greetings", {
                        ns: Namespaces.commons,
                        userNameAndSpace: user?.firstName ? `${user?.firstName} ` : "",
                    })}
                </Typography>
                <Typography color="white" variant="body1" sx={{ marginBottom: -1.25 }}>
                    {user ? ( // user profile loaded
                        user?.balance ? ( // user has points
                            t("you_have_points", {
                                ns: Namespaces.glossary,
                                count: user?.balance,
                            })
                        ) : ( // user have no points
                            t("you_have_no_points", {
                                ns: Namespaces.glossary,
                            })
                        )
                    ) : ( // user profile loading
                        t("loading", {
                            ns: Namespaces.commons,
                        })
                    )}
                </Typography>
            </Toolbar>

            <Tabs
                value={currentTab}
                aria-label="Navigation principale"
                sx={{
                    marginLeft: 1,
                    "& .MuiTabs-flexContainer": {
                        height: "100%",
                    },
                    "& .MuiTabs-indicator": {
                        height: 6,
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                        backgroundColor: 'primary.main',
                    },
                }}
            >
                {routes.map((route, index) => (
                    <AppBarTab
                        key={index}
                        icon={route.icon}
                        label={route.label}
                        to={route.url}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
}

export default WebAppBar;