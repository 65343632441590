import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Reward from 'models/Shop/Reward';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import FavoriteButton from 'components/_include/FavoriteButton';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { GOLDEN_NUMBER } from 'constants/layout';
import { useAppSelector } from 'store/hooks';
import { EntityId } from '@reduxjs/toolkit';
import { selectRewardById } from 'store/reducers/rewards/list';

interface RewardCardProps {
    rewardId: EntityId;
    contentHeight?: number;
    actionsHeight: number;
    onOpenPurchaseDialog: (reward: Reward) => void;
}

function RewardCard({ rewardId, contentHeight, actionsHeight, onOpenPurchaseDialog, }: RewardCardProps) {

    const reward = useAppSelector(state => selectRewardById(state, rewardId));

    const userBalance = useAppSelector(state => state.users.data?.balance || 0)

    const { t } = useTranslation([Namespaces.actions, Namespaces.glossary]);

    if (!reward) return (<></>);

    return (
        <Card
            elevation={6}
            sx={{ flexGrow: 1 }}
        >
            <CardMedia
                image={reward.imageURL}
                title={reward.name}
                sx={{
                    height: 0,
                    paddingTop: `${100 / GOLDEN_NUMBER}%`,
                }}
            >
                <Avatar
                    alt={reward.partner.name}
                    src={reward.partner.imageURL}
                    sx={{
                        backgroundColor: "white",
                        width: (theme) => theme.spacing(10),
                        height: (theme) => theme.spacing(10),
                        padding: .75,
                        left: (theme) => theme.spacing(4),
                        marginTop: -5,
                        boxShadow: 6,

                        "& img": {
                            borderRadius: "50%",
                        },
                    }}
                />
                <Box
                    bgcolor='primary.main'
                    borderRadius={4}
                    px={2}
                    py={.5}
                    marginRight={3}
                    marginTop={-2}
                    sx={{
                        float: "right",
                    }}>
                    <Typography color="white">
                        {t("point", { ns: Namespaces.glossary, count: reward.cost })}
                    </Typography>
                </Box>
            </CardMedia>
            <CardContent
                sx={{
                    paddingTop: 7,
                    height: contentHeight || "auto"
                }}
            >
                <Typography
                    variant="caption"
                >
                    {t("offered_by", { ns: Namespaces.glossary, name: reward.partner.name })}
                </Typography>
                <Typography
                    variant="h5"
                    marginBottom={2}
                >
                    {reward.name}
                </Typography>
                <Typography
                    variant="body1"
                >
                    {reward.description}
                </Typography>
            </CardContent>

            <CardActions
                sx={{
                    justifyContent: "space-between",
                    height: actionsHeight,
                }}
            >
                <FavoriteButton
                    rewardId={reward.id}
                />
                {/* <Button
                    color="primary"
                    // startIcon={<FavoriteBorderIcon />}
                >
                    {t("add_to_favorites", { ns: Namespaces.actions })}
                </Button> */}
                {userBalance >= reward.cost ? ( // enough points to purchase reward
                    <Button
                        color="primary"
                        startIcon={<ShoppingBasketOutlinedIcon />}
                        onClick={() => onOpenPurchaseDialog(reward)}
                    >
                        {t("exchange_for", { ns: Namespaces.actions, count: reward.cost })}
                    </Button>
                ) : ( // missing points
                    <Button
                        color="primary"
                        startIcon={<LockIcon />}
                        disabled
                    >
                        {t("missing_point", { ns: Namespaces.glossary, count: reward.cost - userBalance })}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
}


export default RewardCard;