import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RewardFilter, RewardsCategories, RewardsFilters } from "../../../models/Shop/Reward";

type RewardsContext = {
    list: RewardsFilters;
};

const initialState: RewardsContext = {
    list: {
        [RewardFilter.CATEGORY]: Object.values(RewardsCategories),
        [RewardFilter.LOCATION]: {
            selected: false,
        },
        [RewardFilter.FAVORITES]: false,
    }
};


export const rewardsFiltersSlice = createSlice({
    name: 'rewards_filters',
    initialState: initialState,
    reducers: {
        setFilters: (state, { payload: filters }: PayloadAction<RewardsFilters>) => {
            state.list = filters;
        },
    },
});

export const RewardsFiltersActions = rewardsFiltersSlice.actions;

const RewardsFiltersReducer = rewardsFiltersSlice.reducer;

export default RewardsFiltersReducer;