import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext } from "../../store";

type PurchasesContext = LoadableContext & {
    loaded: boolean;
};

const initialState: PurchasesContext = {
    loading: false,
    loaded: false,
    error: null,
};


export const pastPurchasesSlice = createSlice({
    name: 'past_purchases',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setLoaded: (state, { payload: loaded }: PayloadAction<boolean>) => {
            state.loading = false;
            state.loaded = loaded;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const PastPurchasesActions = pastPurchasesSlice.actions;

const PastPurchasesReducer = pastPurchasesSlice.reducer;

export default PastPurchasesReducer;