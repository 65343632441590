import { Link, Paper, styled } from '@mui/material';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { Contact } from 'constants/contacts';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Namespaces } from 'locales/translations';

interface ContactsCardProps {
    contact: Contact,
}

const ContactCell = styled(TableCell)({
    border: "none",
});

function ContactsCard(props: ContactsCardProps) {
    const { contact } = props;

    const { t } = useTranslation([Namespaces.commons]);

    return (
        <Paper sx={{
            padding: {
                xs: 1,
                md: 2,
            }
        }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <ContactCell sx={{ width: 0 }}>{t("name", { ns: Namespaces.commons })}:</ContactCell><ContactCell>{contact.name}</ContactCell>
                    </TableRow>
                    <TableRow>
                        <ContactCell>{t("role", { ns: Namespaces.commons })}:</ContactCell><ContactCell>{contact.role}</ContactCell>
                    </TableRow>
                    <TableRow>
                        <ContactCell>{t("email", { ns: Namespaces.commons })}:</ContactCell>
                        <ContactCell>
                            <Link
                                href={`mailto:${contact.email}`}>
                                {contact.email}
                                <EmailIcon sx={{
                                    margin: "-2px 8px -6px",
                                }} />
                            </Link>
                        </ContactCell>
                    </TableRow>
                    {contact.phone &&
                        <TableRow>
                            <ContactCell>{t("phone", { ns: Namespaces.commons })}:</ContactCell><ContactCell>{contact.phone}</ContactCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Paper>
    );
}

export default ContactsCard;