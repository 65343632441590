import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'helpers/forms';

export type CompleteContactFormInputs = {
    firstName: TextInput,
    lastName: TextInput,
    email: TextInput,
}

interface CompleteContactInputsProps {
    data: CompleteContactFormInputs;
    onInputChange: (name: string, value: string) => void;
}

function CompleteContactInputs(props: CompleteContactInputsProps) {

    const { data, onInputChange, } = props;

    const { t } = useTranslation([Namespaces.forms,]);

    const getPlaceholder = (input: string) => {
        return t(`profile.${input}`, { ns: Namespaces.forms });
    }

    const { firstName, lastName, email } = data;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    label={getPlaceholder("firstName")}
                    value={firstName.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('firstName', event.target.value) }
                    }}
                    error={Boolean(firstName.error)}
                    helperText={firstName.error}
                />
            </Grid>
            <Grid item xs={12}>

                <TextField
                    label={getPlaceholder("lastName")}
                    value={lastName.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('lastName', event.target.value) }
                    }}
                    error={Boolean(lastName.error)}
                    helperText={lastName.error}
                />
            </Grid>

            <Grid item xs={12}>

                <TextField
                    label={getPlaceholder("email")}
                    value={email.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('email', event.target.value) }
                    }}
                    error={Boolean(email.error)}
                    helperText={email.error}
                    required
                />
            </Grid>
        </Grid>
    )
}

export default CompleteContactInputs;