import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import UserAction from "actions/user";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatEmailAddress } from "helpers/strings";
import { useTranslation } from "react-i18next";
import { Namespaces } from "locales/translations";
import { Padding } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";


type EmailLogInViewProps = {

}

function EmailLoginView(props: EmailLogInViewProps) {
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const handleClickOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const loading = useAppSelector(state => state.users.loading);

    const { t } = useTranslation([Namespaces.actions]);

    const [email, setEmail] = useState("");

    const dispatch = useAppDispatch();

    const handleSendClicked = () => {
        dispatch(UserAction.sendSignInLink(formatEmailAddress(email)));
    }

    const handleCloseEmailDialog = () => {
        setOpenEmailDialog(false);
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    endIcon={<EmailIcon />}
                    color='info'
                    size='large'
                    onClick={handleClickOpenEmailDialog}
                >
                    {t("email_connect", { ns: Namespaces.actions })}
                </Button>
            </Box>
            <Dialog open={openEmailDialog} onClose={handleCloseEmailDialog}>
                <DialogTitle>{t("send_email_link", { ns: Namespaces.titles })}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="adresse"
                        label="Adresse Email"
                        type="email"
                        value={email}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: 3 }} >
                    <LoadingButton
                        loading={loading}
                        onClick={handleSendClicked}
                        variant="contained"
                        color="success"
                    >
                        {t("send", { ns: Namespaces.actions })}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default EmailLoginView;