import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import moment from "moment";
import "moment/locale/fr";

import { initializeApp } from 'firebase/app';

import { configureTranslations } from "locales/translations";

moment.locale("fr");

configureTranslations();

initializeApp(require(`firebase_config/${process.env.REACT_APP_FIREBASE_CONFIG}`));

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
