import React, { useState, FormEvent } from 'react';
import { Grid, Box, } from '@mui/material';
import { styled } from '@mui/system';
import ActionButton from 'components/_include/ActionButton';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import CompleteContactInputs, { CompleteContactFormInputs } from './CompleteContactInputs';
import CompleteAddressInputs, { CompleteAddressFormInputs } from './CompleteAddressInputs';
import { Dictionary } from 'helpers/types';
import { CompleteProfileData } from 'models/User';

export type CompleteProfileFormInputs = CompleteContactFormInputs & CompleteAddressFormInputs;

interface CompleteProfileFormProps {
    initialData: CompleteProfileFormInputs;
    loading: boolean;
    onSubmit: (profileData: CompleteProfileData) => void;
}

const GridColumn = styled(Grid)({
    display: "flex",
    alignItems: "center",
});

function CompleteProfileForm(props: CompleteProfileFormProps) {

    const { initialData, loading, onSubmit } = props;

    const { t } = useTranslation([Namespaces.actions, Namespaces.forms, Namespaces.snacks, Namespaces.titles]);

    const [inputs, setInputs] = useState<CompleteProfileFormInputs>(initialData);

    const { firstName, lastName, email, street_address, additional_information, city, postcode } = inputs;

    /**
     * Save the input value in the state and remove any error
     * @param name The name of the input
     * @param value The entered value
     */
    const handleInputChange = (name: string, value: string) => {
        setInputs({
            ...inputs,
            [name]: {
                value: value,
                error: null,
            },
        });
    }


    const handleSubmitPressed = (event: FormEvent) => {
        event.preventDefault();

        let error = false;

        if (!street_address.value) {
            error = true;
            street_address.error = "";
        }

        if (!city.value) {
            error = true;
            city.error = "";
        }

        if (!postcode.value) {
            error = true;
            postcode.error = "";
        }

        if (error) {
            setInputs({
                firstName: firstName,
                lastName: lastName,
                email: email,
                street_address: street_address,
                additional_information: additional_information,
                city: city,
                postcode: postcode,
            });
        }
        else {
            const profileData: CompleteProfileData = {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                address: {
                    street_address: street_address.value,
                    additional_information: additional_information.value,
                    city: city.value,
                    postcode: postcode.value,
                },
            };

            onSubmit(profileData);
        }
    }

    const formIsValid = street_address.value.length && city.value.length && postcode.value.length;

    return (
        <Box sx={{
            marginTop: 2,
            // maxWidth: 1280,
            marginBottom: 0,
            padding: 2,
            paddingTop: 3,
            textAlign: 'center',
        }}>
            <form
                autoComplete="on"
                method="post"
                action="#"
            >
                <Grid container sx={{ marginTop: 5 }}>
                    <GridColumn item xs={12} sm={6}>
                        <CompleteContactInputs 
                            data={{
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                            }}
                            onInputChange={handleInputChange}
                            />
                    </GridColumn>

                    <GridColumn item xs={12} sm={6}>
                        <CompleteAddressInputs 
                            data={{
                                street_address: street_address,
                                additional_information: additional_information,
                                city: city,
                                postcode: postcode,
                            }}
                            onInputChange={handleInputChange}
                            />
                    </GridColumn>
                </Grid>

                <Box textAlign="center" sx={{ marginTop: 3, }}>
                    <ActionButton
                        color="gradient"
                        disabled={!formIsValid}
                        loading={loading}
                        onClick={(event) => handleSubmitPressed(event)}
                        type="submit"
                    >
                        {t("update", { ns: Namespaces.actions })}
                    </ActionButton>
                </Box>
            </form>
        </Box>
    )
}

export default CompleteProfileForm;