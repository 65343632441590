import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Message from "../../../models/Message";
import { RootState } from "store/store";


const MAX_NB_MESSAGES = 1000;

const messagesAdapter = createEntityAdapter<Message>({
    selectId: message => message.message,
    sortComparer: (m1, m2) => {
        if (m1.timestamp < m2.timestamp) {
            return -1;
        } else if (m1.timestamp > m2.timestamp) {
            return 1;
        }
        return 0;
    }
});

type MessageContext = {
    loading: boolean,
    loadingOlder: boolean,
    error: Error | null,
    firstItemIndex: number,
}

const initialState: MessageContext = {
    loading: false,
    loadingOlder: false,
    error: null,
    firstItemIndex: MAX_NB_MESSAGES,
}

const messageSlice = createSlice({
    name: 'list',
    initialState: messagesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingMessages: (state) => {
            state.loadingOlder = true;
            state.error = null;
        },
        startLoadingOlderMessages: (state) => {
            state.loadingOlder = true;
            state.error = null;
        },
        loadMessages: (state, { payload: messages }: PayloadAction<Message[]>) => {
            state.loading = false;
            messagesAdapter.setAll(state, messages);
            state.firstItemIndex = MAX_NB_MESSAGES;
        },
        loadOlderMessages: (state, { payload: messages }: PayloadAction<Message[]>) => {
            state.loadingOlder = false;
            messagesAdapter.upsertMany(state, messages);
            state.firstItemIndex -= messages.length;
        },
        finishSendingMessage: (state, { payload: { message } }: PayloadAction<{ message: Message }>) => {
            messagesAdapter.addOne(state, message);
        },
        setError: (state, { payload }: PayloadAction<Error>) => {
            state.loading = false;
            state.error = payload;
            messagesAdapter.removeAll(state);
        }
    }
});

export const MessageActions = messageSlice.actions;

export const {
    selectAll: selectAllMessages,
    selectById: selectMessageById,
    selectIds: selectMessagesIds
} = messagesAdapter.getSelectors((state: RootState) => state.messages.list)

const MessageReducer = messageSlice.reducer;

export default MessageReducer;