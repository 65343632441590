import React, { } from 'react';
import { connect } from 'react-redux';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import SlideUpTransition from 'components/_include/Transitions/SlideUpTransition';
import CloseIcon from '@mui/icons-material/Close';
import CompleteProfileForm, { CompleteProfileFormInputs } from './CompleteProfileForm';
import UserAction from 'actions/user';
import { Error } from 'actions/actions';
import { useEffect } from 'react';
import { usePrevious } from 'helpers/refs';
import User from 'models/User';
import { Dictionary } from 'helpers/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';


interface CompleteProfileDialogProps {
    open: boolean;
    onClose: () => void;
}

function CompleteProfileDialog(props: CompleteProfileDialogProps) {

    const { open, onClose } = props;

    const user = useAppSelector(state => state.users.data);
    const userLoading = useAppSelector(state => state.users.loading);
    const userError = useAppSelector(state => state.users.error);

    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespaces.actions, Namespaces.titles]);

    const userAddress = user?.address;

    const initialData: CompleteProfileFormInputs = {
        firstName: {
            value: user?.firstName || "",
            error: null,
        },
        lastName: {
            value: user?.lastName || "",
            error: null,
        },
        email: {
            value: user?.email || "",
            error: null,
        },
        street_address: {
            value: userAddress?.street_address || "",
            error: null,
        },
        additional_information: {
            value: userAddress?.additional_information || "",
            error: null,
        },
        city: {
            value: userAddress?.city || "",
            error: null,
        },
        postcode: {
            value: userAddress?.postcode || "",
            error: null,
        },
    };

    const handleFormSubmitted = (profileData: Dictionary) => {
        dispatch(UserAction.completeProfile(profileData));
    };

    const wasLoading = usePrevious(userLoading);
    useEffect(() => {
        if (open && wasLoading && !userLoading && !userError) { // form successfully submitted
            onClose();
        }
    }, [userLoading, userError]);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={SlideUpTransition}
        >
            <AppBar color="secondary">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label={t("close", { ns: Namespaces.actions })}
                        disabled={userLoading}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        variant="h6"
                        sx={{
                            marginLeft: 2,
                            flex: 1,
                        }}>
                        {t("complete_profile", { ns: Namespaces.titles })}
                    </Typography>
                </Toolbar>
            </AppBar>

            <CompleteProfileForm
                loading={userLoading}
                initialData={initialData}
                onSubmit={(profileData) => {
                    handleFormSubmitted(profileData);
                }}
            />
        </Dialog>
    );
}

export default CompleteProfileDialog;