import { AppDispatch } from "store/store";
import urls from "../constants/urls";
import { getCurrentTimestamp } from "../helpers/dates";
import Message, { MessageAttachment, MessageData } from "../models/Message";
import { fetchAPI } from "./actions";
import { SendingMessageActions } from "store/reducers/messages/sending_message";
import { MessageActions } from "store/reducers/messages/list";

const listUserMessages = (newer: boolean, timestamp?: moment.Moment, limit?: number,) => {
    return (dispatch: AppDispatch) => {

        if (newer || !timestamp) {
            dispatch(MessageActions.startLoadingMessages());
        }
        else {
            dispatch(MessageActions.startLoadingOlderMessages());
        }

        let queryParams = ``;

        if (limit) { // load older messages or first time loading messages
            queryParams += `limit=${limit}`;
        }

        if (timestamp && newer) {
            queryParams += `&order=asc&after=${timestamp.format('x')}`;
        }
        else if (timestamp) { // load older messages
            queryParams += `&order=asc&before=${timestamp.format('x')}`;
        }
        else { // first time loading messages
            queryParams += `&order=asc&before=${getCurrentTimestamp()}`;
        }

        return fetchAPI(`${urls.API.V3}/message?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((messagesData: MessageData[]) => {
                const messages: Message[] = messagesData.map(messageData => new Message(messageData));
                if (newer || !timestamp) {
                    dispatch(MessageActions.loadMessages(messages));
                }
                else {
                    dispatch(MessageActions.loadOlderMessages(messages));
                }
            })
            .catch(error => {
                dispatch(MessageActions.setError(error))
                console.error("Failed loading messages:", error);
            });
    }
}

const sendMessage = (text: string, attachment?: MessageAttachment) => {
    return (dispatch: AppDispatch) => {
        let message = new Message({
            timestamp: getCurrentTimestamp(),
            from: "cocon",
            message: text,
            attachment: attachment,
        });

        dispatch(SendingMessageActions.StartSendingMessage(message));

        return fetchAPI(`${urls.API}/message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message),
        })
            .then((messageData: MessageData) => {
                message = new Message(messageData);
                dispatch(SendingMessageActions.finishSendingMessage());
            })
            .catch(error => {
                dispatch(SendingMessageActions.setError(error));
                console.error("Failed sending message:", error);
            });
    }
};

const MessageAction = {
    listUserMessages,
    sendMessage,
};

export default MessageAction;