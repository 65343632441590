import { Divider } from "@mui/material";
import React from "react";

function SectionDivider() {
    return (
        <Divider 
            light
            sx={{
                width: "100%",
                maxWidth: 480,
                my: 4,
            }}
            />
    )
}

export default SectionDivider;