import React, { useState } from 'react';
import { Box, CssBaseline, Link, Typography, } from '@mui/material';
import { Route, Routes } from "react-router-dom";
import LoginPage from './Login/LoginPage';
import HomePage from './Home/HomePage';
import CompleteProfilePage from './Users/Profile/CompleteProfile/CompleteProfilePage';
import VerifyEmailPage from './Users/Profile/VerifyEmailPage';
import { History } from 'history';
import ContactsPage from './Contacts/ContactsPage';
import ResetPasswordPage from './Users/Profile/ResetPasswordPage';
import WebAppBar from './_include/WebAppBar/WebAppBar';
import ShopPage from './Shop/ShopPage';
import CoconChatPage from './Chat/CoconChatPage';
import urls from 'constants/urls';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import firebase from 'firebase/app';
import UserAction from 'actions/user';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={urls.WEBSITE}>
                Ficha
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



type PageProps = {

}

function Pages(props: PageProps) {

    const { } = props;

    const location = useLocation();
    const navigate = useNavigate();

    // pages that do not require logging in
    const loggedOutPages = ['/login', '/register', '/complete-profile', '/verify-email', '/reset-password'];

    let isLoggedOutPage = false;
    for (let loggedOutPage of loggedOutPages) {
        if (loggedOutPage === location.pathname || `${loggedOutPage}/` === location.pathname) {
            isLoggedOutPage = true;
        }
    }

    const [currentUserLoaded, setCurrentUserLoaded] = useState(false);
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (!user && !isLoggedOutPage) {// user needs to log in
            navigate(`/login/?next=${location.pathname}`);
        }
        else {
            setCurrentUserLoaded(true); // user has been loaded, page can be displayed
        }
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            minHeight: '100vh',
        }}>
            <CssBaseline />
            {!isLoggedOutPage && currentUserLoaded ? (
                <WebAppBar />
            ) : (
                null
            )}
            {currentUserLoaded ? (
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/shop" element={<ShopPage />} />
                    <Route path='/chat' element={<CoconChatPage />} />
                    <Route path='/contacts' element={<ContactsPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/verify-email" element={<VerifyEmailPage />} />
                    <Route path="/complete-profile" element={<CompleteProfilePage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                </Routes>
            ) : (
                null
            )}

        </Box>
    );
}

export default Pages;