import { createTheme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';

const defaultTheme = createTheme();

let theme = createTheme({
    palette: {
        primary: {
            main: '#F14785',
        },
        secondary: {
            main: '#610C81',
        },
    },
    typography: {
        h1: {
            fontWeight: 300,
        },
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    position: "relative",
                    flexGrow: 1,
                    marginTop: defaultTheme.spacing(2),
                    marginBottom: defaultTheme.spacing(4),
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: defaultTheme.spacing(3),
                    maxWidth: defaultTheme.spacing(50),
                    width: '90%',
                },
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

const tableHead: CSSProperties = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
};

theme.typography = {
    ...theme.typography,
    h1: {
        fontSize: '2rem',
        marginBottom: 32,
        marginTop: 32,
        [theme.breakpoints.up('sm')]: { // not mobile
            fontSize: '3rem',
        },
        [theme.breakpoints.up('md')]: { // not mobile
            fontSize: '4rem',
        },
    },
    h4: {
        fontFamily: 'CircularStd',
        fontWeight: "bold",
        fontSize: 28,
    },
    h5: {
        fontFamily: 'CircularStd',
        fontWeight: "bold",
        fontSize: "1.2rem",
        lineHeight: 1.4,
        color: "#444444",
    },
    body1: {
        fontFamily: 'CircularStd',
        lineHeight: 1.75,
    },
};

theme = createTheme(theme, {
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    [theme.breakpoints.down('sm')]: {
                        fontSize: 13,
                    }
                }
            }
        },
        MuiTypography: {
            root: {
                whiteSpace: "break-spaces",
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: theme.spacing(8),
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
                margin: 0,
            },
        },
        MuiButton: {
            root: {
                margin: "0.5rem",
                padding: "1rem 2rem",
            },
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiTableCell: {
            head: tableHead
        },
        MuiTableHead: {
            root: tableHead
        },
        MuiTextField: {
            root: {
                marginBottom: "1.5rem",
                maxWidth: 400,
                width: '90%',
            }
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: "white",
            }
        },
        MuiDialogContent: {
            root: {
                paddingTop: 0,
                paddingBottom: theme.spacing(2),
            }
        },
        MuiGridListTile: {
            tile: {
                display: "flex",
                overflow: "visible",
                alignItems: "center",
            }
        }
    },
});

// theme = responsiveFontSizes(theme);

export default theme;