import moment from 'moment';

export function getCurrentTimestamp() {
    return Number(moment().format('x'));
}

interface DisplayDateOptions {
    day?: boolean;
    month?: boolean;
    year?: boolean;
    time?: boolean;
}

export function getDisplayableFrenchDate(timestamp: number, displayOptions: DisplayDateOptions = {}) {
    const options = {
        day: displayOptions.day !== undefined ? displayOptions.day : true, 
        month: displayOptions.month !== undefined ? displayOptions.month : true, 
        year: displayOptions.year !== undefined ? displayOptions.year : true, 
        time: displayOptions.time !== undefined ? displayOptions.time : false, 
    };

    // les noms de jours / mois
    var mois = new Array("janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre");
    // on recupere la date
    var date = new Date(timestamp);
    // on construit le message
    let message = "";
    if (options.day) {
        message += date.getDate() + " ";   // numero du jour
    }
    if (options.month) {
        message += mois[date.getMonth()] + " ";   // mois
    }
    if (options.year) {
        message += date.getFullYear();
    }

    if (options.time) {
        message += ` à ${date.getHours()}:${date.getMinutes()}`;
    }
    
    return message;
}