import { EntityId } from "@reduxjs/toolkit";
import { MessageAttachmentType } from "models/Message";
import { useAppSelector } from "store/hooks";
import { selectMessageById } from "store/reducers/messages/list";
import MessageBubble from "./MessagesBubbles/MessageBubble";
import MediaBubble from "./MessagesBubbles/MediaBubble";
import LinkBubble from "./MessagesBubbles/LinkBubble";


type MessageItemProps = {
    messageId: EntityId
}

function MessageItem(props: MessageItemProps) {
    const { messageId } = props;
    const message = useAppSelector(state => selectMessageById(state, messageId));


    if (!message) return <></>;

    switch (message.attachment?.type) {
        case MessageAttachmentType.LINK:
        case MessageAttachmentType.INTERNAL_LINK:
            return (
                <LinkBubble key={messageId} message={message} />
            );

        case MessageAttachmentType.VIDEO:
        case MessageAttachmentType.IMAGE:
            return (
                <MediaBubble key={messageId} message={message} />
            );
    }

    // simple message from user or Cocon
    return (
        <MessageBubble key={messageId} message={message} />
    )
}

export default MessageItem;