import urls from "constants/urls";
import Stats from "models/Stats";
import { fetchAPI } from "./actions";
import { AppDispatch } from "store/store";
import { StatsActions } from "store/reducers/stats";

const getCommunityStats = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(StatsActions.startLoading());

        return fetchAPI(`${urls.API.V3}/statistics/latest`, {
            method: 'GET',
        })
            .then(async statsData => {
                const stats = new Stats(statsData);
                dispatch(StatsActions.setStats(stats));
                return;
            })
            .catch(error => {
                dispatch(StatsActions.setError(error))
                // alert("No stats found");
                console.error(error);
                return null;
            })
    }
}

const StatsAction = {
    getCommunityStats,
}

export default StatsAction;