import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Stats from "models/Stats";


export type StatsContext = {
    stats: Stats | null,
    loading: boolean,
    error: Error | null,
}

const initialState: StatsContext = {
    stats: null,
    loading: false,
    error: null,
};


export const statsSlice = createSlice({
    name: 'stats',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setStats: (state, { payload: stats }: PayloadAction<Stats>) => {
            state.loading = false;
            state.stats = stats
        },
        setError: (state, { payload: error }: PayloadAction<Error>) => {
            state.loading = false;
            state.error = error;
        },
    }
})

export const StatsActions = statsSlice.actions;

const StatsReducer = statsSlice.reducer;

export default StatsReducer;