import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Namespaces } from 'locales/translations';
import Rank, { Ranks } from 'models/Rank';
import { Box, ImageList, Typography, useMediaQuery, useTheme } from '@mui/material';
import RankBadgeWrapper from './RankBadgeWrapper';
import { useAppSelector } from 'store/hooks';

type RanksProgressProps = {

};

function RanksProgress(props: RanksProgressProps) {


    const userScore = useAppSelector(state => state.users.data?.score || 0);
    const userRank = useAppSelector(state => state.users.data?.rank);


    const theme = useTheme();
    const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

    let NB_BADGES_ON_SCREEN = 5;
    let BADGE_WIDTH_PERCENTAGE = 15;
    let CURRENT_BADGE_WIDTH_PERCENTAGE = 25;

    if (isDownXs) {
        NB_BADGES_ON_SCREEN = 2;
        BADGE_WIDTH_PERCENTAGE = 30;
        CURRENT_BADGE_WIDTH_PERCENTAGE = 50;
    }
    else if (isDownSm) {
        NB_BADGES_ON_SCREEN = 2;
        BADGE_WIDTH_PERCENTAGE = 15;
        CURRENT_BADGE_WIDTH_PERCENTAGE = 25;
    }
    else if (isDownXs) {
        NB_BADGES_ON_SCREEN = 3;
    }

    const TILE_WIDTH = window.innerWidth / NB_BADGES_ON_SCREEN;
    const BADGE_WIDTH = TILE_WIDTH * (BADGE_WIDTH_PERCENTAGE / 100);
    const LIST_LEFT_MARGIN = (window.innerWidth - BADGE_WIDTH) / 2;
    const CURRENT_BADGE_WIDTH = TILE_WIDTH * (CURRENT_BADGE_WIDTH_PERCENTAGE / 100);

    const { t } = useTranslation([Namespaces.glossary, Namespaces.ranks]);

    const gridListRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
        if (gridListRef && userRank) {
            const scrollX = TILE_WIDTH * (userRank.rankNumber - 1) - (BADGE_WIDTH - CURRENT_BADGE_WIDTH) / 2;
            gridListRef.current?.scrollTo(scrollX, 0);
        }
    }, [gridListRef, userRank]);

    if (!userRank) return (<></>);

    const pointsToNextLevel = userRank.nextRank ? userRank.nextRank - userScore : 0;

    const ranks = Object.values(Ranks)
        .filter(rankLabel => rankLabel !== Ranks.NO_RANK)
        .map((rankLabel, index) => {
            return new Rank({
                rankNumber: index + 1,
                score: index,
                label: rankLabel,
                nextRank: index + 2
            });
        });

    const LIST_HEIGHT = 120;

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            my={2}
        >
            <ImageList
                ref={gridListRef}
                cols={NB_BADGES_ON_SCREEN}
                gap={0}
                rowHeight={LIST_HEIGHT}
                sx={{
                    flexWrap: "nowrap",
                    width: "100vw",
                    height: LIST_HEIGHT,
                    gridAutoFlow: "column",
                    px: `${LIST_LEFT_MARGIN}px`,
                }}
            >
                {ranks.map((rank, index) => (
                    <RankBadgeWrapper
                        rank={rank}
                        key={index}
                        index={index}
                        width={TILE_WIDTH}
                        badgeWidth={BADGE_WIDTH}
                        badgeWidthPercentage={BADGE_WIDTH_PERCENTAGE}
                        currentBadgeWidthPercentage={CURRENT_BADGE_WIDTH_PERCENTAGE}
                        isLastRank={index === ranks.length - 1}
                    />
                ))}
            </ImageList>

            <Box
                marginTop={1}
                textAlign="center"
                bgcolor="rgba(0,0,0,0)"
            >
                <Typography
                    variant="h4"
                    bgcolor="rgba(0,0,0,0)"
                >
                    {t(`${Namespaces.ranks}:${userRank.label}`)}
                </Typography>
                {
                    userRank.nextRank ?
                        <Typography
                            variant="h6"
                            color='primary.main'
                        >
                            {t(`${Namespaces.glossary}:point`, { count: pointsToNextLevel })} {t(`${Namespaces.ranks}:until_next_rank`)}
                        </Typography>
                        :
                        <Typography
                            variant="h6"
                            color='primary.main'
                        >
                            {t(`${Namespaces.ranks}.you_re_a_champion`)}
                        </Typography>
                }
            </Box>

            {/* <RankDialog
        rank={selectedRank?.rank}
        status={selectedRank?.status}
        onDismiss={() => setSelectedRank(undefined)}
      /> */}

        </Box>
    );
};

export default RanksProgress;
