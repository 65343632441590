import { Container, Typography, } from '@mui/material';
import { Namespaces } from 'locales/translations';
import React from 'react';
import { CONTACTS } from 'constants/contacts';
import ContactsCompanySection from './ContactsCompanySection';
import { useTranslation } from 'react-i18next';

interface ContactsPageProps {

}

function ContactPage(props: ContactsPageProps) {
    const { t } = useTranslation([Namespaces.titles]);

    return (
        <Container>
            <Typography
                variant="h1"
            >
                {t("contacts", { ns: Namespaces.titles })}
            </Typography>

            <div>
                {Object.entries(CONTACTS).map(([company, contacts]) => (
                    <ContactsCompanySection
                        key={company}
                        company={company}
                        contacts={contacts}
                    />
                ))}
            </div>
        </Container>
    );
}

export default ContactPage;