import { Container } from '@mui/material';
import RewardsList from './RewardsList';


interface ShopPageProps {

}

function ShopPage(props: ShopPageProps) {

    return (
        <Container sx={{ paddingTop: 3 }}>
            <RewardsList />
        </Container>
    );
}

export default ShopPage;