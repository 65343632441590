import React from 'react';
import { ConnectedProps, connect } from "react-redux";
import { Container, Typography, Alert, } from '@mui/material';
import UserAction from 'actions/user';
import { Error } from 'actions/actions';
import queryString from 'query-string';
import { usePrevious } from 'helpers/refs';
import { Namespaces } from 'locales/translations';
import BackToWebsiteLink from 'components/_include/BackToWebsiteLink';
import { useTranslation } from 'react-i18next';
import SectionLoader from 'components/_include/SectionLoader';
import { Address, } from 'helpers/types';
import { Location } from 'history';
import CompleteProfileForm from './CompleteProfileForm';
import { CompleteProfileData } from 'models/User';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';


const MAX_INPUT_WIDTH = 400;


type CompleteProfilePageProps = {

}

interface AddressDataInputs {
    street_address: {
        value: string,
        error: string | null
    },
    additional_information: {
        value: string,
        error: string | null
    },
    city: {
        value: string,
        error: string | null
    },
    postcode: {
        value: string,
        error: string | null
    },
}

function CompleteProfilePage(props: CompleteProfilePageProps) {

    const userLoading = useAppSelector(state => state.users.loading);
    const userError = useAppSelector(state => state.users.error);

    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("t");

    const { t } = useTranslation([Namespaces.actions, Namespaces.snacks, Namespaces.titles]);

    const wasLoading = usePrevious(userLoading);

    const initialData = {
        firstName: {
            value: "",
            error: null
        },
        lastName: {
            value: "",
            error: null
        },
        email: {
            value: "",
            error: null
        },
        street_address: {
            value: "",
            error: null
        },
        additional_information: {
            value: "",
            error: null
        },
        city: {
            value: "",
            error: null
        },
        postcode: {
            value: "",
            error: null
        },
    };

    const handleSubmitPressed = (profileData: CompleteProfileData) => {
        if (!token) return;
        dispatch(UserAction.completeAddress(profileData, token.toString()));
    }

    if (!token) {
        return (
            <Container>
                <Alert severity="error">
                    {t("invalid_link", { ns: Namespaces.snacks })}
                </Alert>
                <BackToWebsiteLink />
            </Container>
        );
    }

    if (wasLoading && !userLoading && !userError) { // request completed successfully
        return (
            <Container>
                <Alert severity="error">
                    {t("complete_profile.success", { ns: Namespaces.snacks })}
                </Alert>
            </Container>
        );
    }

    if (userLoading) {
        return (
            <Container>
                <SectionLoader />
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h1">
                {t("complete_profile", { ns: Namespaces.titles })}
            </Typography>

            <CompleteProfileForm
                initialData={initialData}
                loading={userLoading}
                onSubmit={(data) => handleSubmitPressed(data)}
            />
        </Container>
    )
}

export default CompleteProfilePage;