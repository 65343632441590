import UserAction from "actions/user";
import Reward from "models/Shop/Reward";
import moment from "moment";
import React, { CSSProperties, useEffect, useState } from "react";
import { connect } from "react-redux";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { IconButton, Theme } from "@mui/material";
import { useAppSelector } from "store/hooks";


interface FavoriteButtonProps {
    rewardId: string;
    size?: "small" | "medium";
    // updateUser: (userData: { id: string, [key: string]: any }) => Promise<void>;
}

function FavoriteButton(props: FavoriteButtonProps) {

    const { rewardId, size } = props;

    const userId = useAppSelector(state => state.users.data?.id);
    const userFavorites = useAppSelector(state => state.users.data?.favoriteRewards || []);

    const [isFavorite, setIsFavorite] = useState(userFavorites.indexOf(rewardId) >= 0);
    const [lastTap, setLastTap] = useState<moment.Moment>();

    const toggleFavorite = () => {
        const now = moment();
        if (lastTap && now.diff(lastTap, "milliseconds") < 800) { // 2 taps less than 800ms appart
            return; // authorize "liking" by double-tapping to replicate Instagram's "like" behavior
        }
        setLastTap(now);

        if (userId) { // user logged in
            let newFavoriteRewards = [...userFavorites];
            if (isFavorite) newFavoriteRewards.splice(newFavoriteRewards.indexOf(rewardId), 1);
            else newFavoriteRewards.push(rewardId);

            setIsFavorite(!isFavorite);

            // updateUser({ id: userId, favoriteRewards: newFavoriteRewards });
        }
    }

    return (
        <IconButton
            color="primary"
            aria-label="Ajouter aux favoris"
            onClick={() => toggleFavorite()}
            size={size}
        >
            {isFavorite ? (
                <FavoriteIcon />
            ) : (
                <FavoriteBorderIcon />
            )}
        </IconButton>
    );
}

export default FavoriteButton;