import urls from "constants/urls";

export interface RankData {
    rankNumber: number;
    score: number;
    label: Ranks;
    nextRank?: number;
}

export default class Rank {
    rankNumber: number;
    score: number;
    label: Ranks;
    nextRank?: number;

    constructor(data: RankData) {
        this.rankNumber = data.rankNumber;
        this.score = data.score;
        this.label = data.label;
        this.nextRank = data.nextRank;
    }

    public data(): RankData {
        return {
            rankNumber: this.rankNumber,
            score: this.score,
            label: this.label,
            nextRank: this.nextRank,
        };
    }

    public getRankImage(): string {
        switch (this.label) {
            case Ranks.NO_RANK: return '';
            case Ranks.TRIEUR_EN_HERBE: return `${urls.IMAGES_FOLDER}/ranks/trieur_en_herbe.png`;
            case Ranks.JEUNE_TRIEUR_PADAWAN: return `${urls.IMAGES_FOLDER}/ranks/jeune_trieur_padawan.png`;
            case Ranks.TRIEUR_EMBUSQUE: return `${urls.IMAGES_FOLDER}/ranks/trieur_embusque.png`;
            case Ranks.TRIEUR_HABILE: return `${urls.IMAGES_FOLDER}/ranks/trieur_habile.png`;
            case Ranks.TRIEUR_DETERMINE: return `${urls.IMAGES_FOLDER}/ranks/trieur_determine.png`;
            case Ranks.TRIEUR_FOU: return `${urls.IMAGES_FOLDER}/ranks/trieur_fou.png`;
            case Ranks.TRIEUR_A_GAGES: return `${urls.IMAGES_FOLDER}/ranks/trieur_a_gages.png`;
            case Ranks.TRIEUR_D_ELITE: return `${urls.IMAGES_FOLDER}/ranks/trieur_d_elite.png`;
            case Ranks.CHEF_DE_TRI_BU: return `${urls.IMAGES_FOLDER}/ranks/chef_de_tri_bu.png`;
        }
    }
}

export enum Ranks {
    NO_RANK = "no_rank",
    TRIEUR_EN_HERBE = "trieur_en_herbe",
    JEUNE_TRIEUR_PADAWAN = "jeune_trieur_padawan",
    TRIEUR_EMBUSQUE = "trieur_embusque",
    TRIEUR_HABILE = "trieur_habile",
    TRIEUR_DETERMINE = "trieur_determine",
    TRIEUR_FOU = "trieur_fou",
    TRIEUR_A_GAGES = "trieur_a_gages",
    TRIEUR_D_ELITE = "trieur_d_elite",
    CHEF_DE_TRI_BU = "chef_de_tri_bu",
};

export type BadgeRankStatus = "locked" | "current" | "completed" | "new";