import { Box } from "@mui/material";
import Rank from "models/Rank";
import React from "react";
import BadgeContent from "./BadgeContent";
import CheckIcon from '@mui/icons-material/Check';

interface CompletedRankProps {
    rank: Rank,
}

function CompletedRank(props: CompletedRankProps) {
    const { rank } = props;

    return (
        <BadgeContent>
            <img
                src={rank.getRankImage()}
                style={{
                    width: "100%",
                    height: "100%",
                    position: 'absolute',
                }}
            // resizeMode="cover"
            />
            <Box 
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor='rgba(0, 0, 0, 0.2)'
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
                <CheckIcon
                    style={{ color: "white" }}
                // color="white" 
                // size={size / 2} 
                />
            </Box>
        </BadgeContent>
    )
}

export default CompletedRank;