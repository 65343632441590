import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material';
import Reward from 'models/Shop/Reward';
import UserAction from 'actions/user';
import RewardsAction from 'actions/rewards';
import RewardCard from './RewardCard';
import { useEffect } from 'react';
import theme from 'constants/theme';
import { GOLDEN_NUMBER } from 'constants/layout';
import PurchaseDialog from './PurchaseDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllRewards, selectRewardsIds } from 'store/reducers/rewards/list';


type PurchasingReward = {
    reward: Reward | null;
    open: boolean;
}

interface RewardsListProps {

}

function RewardsList(props: RewardsListProps) {

    const rewardsIds = useAppSelector(selectRewardsIds);
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const GRID_WIDTH = Math.min(1280, window.innerWidth - 24 * 2);
    const NB_COLUMNS = isUpMd
        ? 3 // large screen (computer)
        : (isUpSm
            ? 2 // small screen (tablet)
            : 1 // very small screens (phone)
        );
    const CARD_SPACING = 32;
    const CARD_WIDTH = (GRID_WIDTH - (NB_COLUMNS - 1) * CARD_SPACING) / NB_COLUMNS;
    const CARD_MEDIA_HEIGHT = CARD_WIDTH / GOLDEN_NUMBER;
    const CARD_CONTENT_HEIGHT = 304;
    const CARD_ACTIONS_HEIGHT = 64;
    const CARD_HEIGHT = CARD_MEDIA_HEIGHT + CARD_CONTENT_HEIGHT + CARD_ACTIONS_HEIGHT;

    useEffect(() => {
        if (!rewardsIds.length) { // load list of rewards if not done yet
            dispatch(RewardsAction.reloadRewards());
        }
    }, []);

    const [purchasingReward, setPurchasingReward] = useState<PurchasingReward>({
        reward: null,
        open: false,
    });

    const onClosePurchaseDialog = () => {
        setPurchasingReward({
            ...purchasingReward,
            open: false,
        });
    };

    const handlePurchaseDialogOpen = useCallback((reward: Reward) => setPurchasingReward({
        reward: reward,
        open: true,
    }), [rewardsIds, setPurchasingReward]);

    return (
        <ImageList
            cols={NB_COLUMNS}
            rowHeight="auto"
            gap={CARD_SPACING}
        >
            {rewardsIds.map((rewardId) => (
                <ImageListItem key={rewardId} cols={1}>
                    <RewardCard
                        rewardId={rewardId}
                        contentHeight={NB_COLUMNS > 1 ? CARD_CONTENT_HEIGHT : undefined}
                        actionsHeight={CARD_ACTIONS_HEIGHT}
                        onOpenPurchaseDialog={handlePurchaseDialogOpen}
                    />
                </ImageListItem>
            ))}
            <PurchaseDialog
                reward={purchasingReward.reward}
                open={purchasingReward.open}
                onClose={onClosePurchaseDialog}
            />
        </ImageList>
    );
}

export default RewardsList;