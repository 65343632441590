
/**
 * Make the first character of a string uppercase
 * @param {string} string The string to transform
 * @return {string} The string with the first character uppercased
 */
export function ucFirst(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Check if an email address has a valid format
 * @param {string} emailAddress The email address to check
 * @return {boolean} True if valid, false otherwise
 */
export function isValidEmail(emailAddress: string): boolean {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

export function isValidURL(text: string, options?: { acceptFacebookScheme?: boolean }) {
    let URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/);
    if (options?.acceptFacebookScheme) { // accept "{{facebookScheme}}/123456" (e.g. to redirect to Ficha's page on Facebook mobile app)
        URLRegExp = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|{{facebookScheme}}(\/[a-zA-Z0-9]*)*)/);
    }

    return text.match(URLRegExp) !== null;
}


export const formatEmailAddress = (email: string) => {
    return email.trim().toLowerCase();
}


export const formatPhoneNumber = (phoneNumber: string) => {
    let phone = phoneNumber;
    if (phone.startsWith("0") && phone.length === 10) phone = phone.substring(1);
    if (!phone.startsWith("+33")) phone = `+33${phone}`;
    return phone;
};

export function getDisplayableURL(url: string): string {
    // remove protocol
    url = url.replace('https://', '');
    url = url.replace('http://', '');
    url = url.replace('www.', '');

    // remove trailing slash '/'
    const lastChar = url.slice(-1);
    if (lastChar === "/") {
        url = url.slice(0, url.length - 1);
    }

    return url;
}