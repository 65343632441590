import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@mui/material';
import Reward, { RewardsCategories, getShopLink } from 'models/Shop/Reward';
import { Namespaces } from 'locales/translations';
import { Trans, useTranslation } from 'react-i18next';
import Purchase from 'models/Shop/Purchase';
import SectionLoader from 'components/_include/SectionLoader';
import RewardsAction from 'actions/rewards';
import { mdiCheck, mdiContentCopy, mdiOpenInNew } from '@mdi/js';
import Icon from "@mdi/react";
import { getDisplayableURL } from 'helpers/strings';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { $CombinedState } from 'redux';


interface PurchaseDialogProps {
    reward: Reward | null;
    open: boolean;
    onClose: () => void;
}

function PurchaseDialog(props: PurchaseDialogProps) {

    const { onClose, reward, open } = props;

    const purchaseInProgress = useAppSelector(state => state.rewards.selected.loading);
    const createdPurchase = useAppSelector(state => state.purchases.selected.data?.reward);
    const userBalance = useAppSelector(state => state.users.data?.balance || 0);


    const { t } = useTranslation([Namespaces.actions, Namespaces.commons, Namespaces.purchases,]);
    const dispatch = useAppDispatch();

    const [promoCodeCopied, setPromoCodeCopied] = useState(false);

    const copyPromoCodeToClipboard = () => {
        const promoCode = createdPurchase?.promoCode;
        if (promoCode) {
            navigator.clipboard.writeText(promoCode)
                .then(() => {
                    setPromoCodeCopied(true);
                })
        }
    }

    if (!reward) return <div />;

    const handleClose = () => {
        if (purchaseInProgress) return; // prevent closing dialog while purchase is loading
        onClose();
    };

    const openPartnerWebsite = (link: string | undefined) => {
        if (link) window.open(link, '_blank');
    };

    const getSuccessfulPurchaseMessage = () => {

        if (!createdPurchase) return;

        switch (createdPurchase.category) {
            case RewardsCategories.E_COMMERCE:
                const shopLink = getShopLink(createdPurchase)
                return (
                    <Trans
                        ns={Namespaces.purchases}
                        i18nKey="trade_successful.e-commerce"
                        values={{
                            promoCode: createdPurchase.promoCode,
                            partner: createdPurchase.partner.name,
                        }}
                        components={{
                            copyButton: (
                                <InlineButton
                                    color="primary"
                                    endIcon={
                                        <Icon
                                            path={promoCodeCopied ? mdiCheck : mdiContentCopy}
                                            title={t("copy", { ns: Namespaces.actions })}
                                            size={1}
                                        />
                                    }
                                    onClick={copyPromoCodeToClipboard}
                                />
                            ),
                            openLinkButton: (
                                shopLink ? (
                                    <InlineButton
                                        color="primary"
                                        endIcon={
                                            <Icon
                                                path={mdiOpenInNew}
                                                title={t("open_link", {
                                                    ns: Namespaces.actions,
                                                    link: getDisplayableURL(shopLink),
                                                })}
                                                size={1}
                                            />
                                        }
                                        onClick={() => openPartnerWebsite(createdPurchase?.partner.website)}
                                    />
                                ) : (
                                    <EmphasedSpan />
                                )
                            )
                        }}
                    />
                );

            case RewardsCategories.LOTTRI:
                return (
                    <Trans
                        ns={Namespaces.purchases}
                        il8nKey="trade_successful.lottri"
                        values={{
                            promoCode: createdPurchase.promoCode,
                        }}
                        components={{
                            emphasis: <EmphasedSpan />
                        }}
                    />
                );

            case RewardsCategories.PHYSICAL:
                return (
                    <Trans
                        ns={Namespaces.purchases}
                        i18nKey="trade_successful.physical"
                        values={{
                            partner: reward.partner.name,
                        }}
                        components={{
                            emphasis: <EmphasedSpan />
                        }}
                    />
                );

            case RewardsCategories.SENT_BY_FICHA:
                return (
                    <Trans
                        ns={Namespaces.purchases}
                        i18nKey="trade_successful.sent_by_ficha"
                        components={{
                            emphasis: <EmphasedSpan />
                        }}
                    />
                );
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {purchaseInProgress ? ( // purchase in progress
                    t("trade_in_progress", { ns: Namespaces.purchases })
                ) : (
                    createdPurchase === null ? ( // before purchase
                        t("exchange_reward", {
                            ns: Namespaces.actions,
                            rewardName: reward.name,
                        })
                    ) : ( // after purchase
                        t("well_done", { ns: Namespaces.commons })
                    )
                )}
            </DialogTitle>
            <DialogContent>
                {purchaseInProgress ? (
                    <Box height={19}>
                        <SectionLoader />
                    </Box>
                ) : (
                    createdPurchase === null ? (
                        <DialogContentText
                            color="textPrimary"
                        >
                            {t("trade_summary", {
                                ns: Namespaces.purchases,
                                balance: userBalance,
                                diff: userBalance - reward.cost,
                            })}
                        </DialogContentText>
                    ) : (
                        <DialogContentText
                            color="textPrimary"
                        >
                            {getSuccessfulPurchaseMessage()}
                            {"\n\n"}
                            <Trans
                                ns={Namespaces.purchases}
                                i18nKey="trade_successful.retrieve_purchase"
                                components={{
                                    emphasis: <EmphasedSpan />
                                }}
                            />
                        </DialogContentText>
                    )
                )}
            </DialogContent>
            {!purchaseInProgress ? (
                createdPurchase === null ? (
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                        >
                            {t("cancel", { ns: Namespaces.actions })}
                        </Button>
                        <Button
                            color="primary"
                            style={{ fontWeight: "bold" }}
                            onClick={() => dispatch(RewardsAction.purchaseReward(reward.id))}
                        >
                            {t("confirm", { ns: Namespaces.actions })}
                        </Button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button
                            color="primary"
                            style={{ fontWeight: "bold" }}
                            onClick={handleClose}
                        >
                            {t("i_understand", { ns: Namespaces.actions })}
                        </Button>
                    </DialogActions>
                )
            ) : undefined}
        </Dialog >
    );
}

const InlineButton = styled(Button)({
    margin: 0,
    marginBottom: 2,
    fontFamily: "CircularStd",
    fontSize: "1rem",
});

const EmphasedSpan = styled(Box)({
    color: 'primary.main',
    fontSize: "1.1rem",
});

export default PurchaseDialog;