import { Box, ImageListItem, useTheme } from '@mui/material';
import Rank from 'models/Rank';
import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import RankBadge from './RankBadge';
import { useAppDispatch, useAppSelector } from 'store/hooks';


type RankBadgeWrapperProps = {
    index: number;
    rank: Rank;
    isLastRank: boolean;
    width: number;
    badgeWidth: number;
    badgeWidthPercentage: number;
    currentBadgeWidthPercentage: number;
}

function RankBadgeWrapper(props: RankBadgeWrapperProps) {
    const { index, rank, isLastRank, width, badgeWidth, badgeWidthPercentage, currentBadgeWidthPercentage } = props;

    const userScore = useAppSelector(state => state.users.data?.score || 0);
    const userRank = useAppSelector(state => state.users.data?.rank);


    const theme = useTheme();

    if (!userRank) return (<></>);

    const pointsToNextLevel = userRank.nextRank ? userRank.nextRank - userScore : 0;

    let status: "completed" | "locked" | "current" = "current";
    if (rank.rankNumber < userRank.rankNumber) {
        status = "completed";
    }
    else if (rank.rankNumber > userRank.rankNumber) {
        status = "locked";
    }

    let tileStyle: CSSProperties = {
        justifyContent: "center",
        width: width,
    };
    let progressStyle: CSSProperties = {
        left: 0,
        right: 0,
    };

    if (index === 0) {
        // tileStyle.marginLeft = `calc(50% - ${badgeWidth / 2}px)`;
        progressStyle.left = `${badgeWidthPercentage / 2}%`;
    }
    if (status === "completed") {
        progressStyle.backgroundColor = theme.palette.primary.main;
    }
    if (status === "current" && userRank.nextRank) {
        const progress = 1 - (pointsToNextLevel / (userRank.nextRank - userRank.score));
        progressStyle.background = `linear-gradient(90.01deg, #F14785 0%, #FF8E53 ${progress * 100}%, ${theme.palette.grey[300]} ${progress * 100}%)`;
    }
    if (status === "locked") {
        progressStyle.background = theme.palette.grey[300];
    }
    if (isLastRank) {
        tileStyle.marginRight = `calc(50% - ${width - badgeWidth / 2}px)`;
        progressStyle.right = `${100 - badgeWidthPercentage / 2}%`;
    }

    return (
        <ImageListItem
            key={index}
            style={tileStyle}
        >
            <Box
                position="absolute"
                height="10%"
                style={progressStyle}
            />
            <RankBadge
                rank={rank}
                status={status}
                badgeWidth={`${status === "current" ? currentBadgeWidthPercentage : badgeWidthPercentage}%`}
            />
        </ImageListItem>
    );
}

export default RankBadgeWrapper;