import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import UserAction from "actions/user";
import SectionLoader from "components/_include/SectionLoader";
import { TOptions } from "i18next";
import { Namespaces } from "locales/translations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

function SignOutDialog(props: DialogProps) {
    const { open, onClose } = props;

    const userLoading = useAppSelector(state => state.users.loading);

    const { t } = useTranslation([Namespaces.user]);

    const dispatch = useAppDispatch();


    const getTranslation = (key: string, context?: TOptions) => t(`sign_out_alert.${key}`, { ns: Namespaces.user, ...context });

    const userEmail = useAppSelector(state => state.users.data?.email);
    const userPhone = useAppSelector(state => state.users.data?.phone);

    const body = userEmail ? (userPhone ? "body_email_phone" : "body_email") : "body_phone";

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t("sign_out", { ns: Namespaces.actions })}
            </DialogTitle>
            <DialogContent>
                {userLoading ? (
                    <SectionLoader />
                ) : (<DialogContentText>
                    {getTranslation(body, { email: userEmail, phone: userPhone })}
                </DialogContentText>)}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => { onClose() }}
                >
                    {t("cancel", { ns: Namespaces.actions })}
                </Button>
                <Button
                    onClick={() => { dispatch(UserAction.dispatchLogOut()) }}
                >
                    {t("sign_out", { ns: Namespaces.actions })}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default SignOutDialog;