import { useState, useEffect } from 'react';
import { Snackbar, Alert, Typography, AlertColor } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actionsResultsActions } from 'store/reducers/actions_result';



type SnackbarProps = {

}


function GlobalSnackbar(props: SnackbarProps) {


    const dispatch = useAppDispatch();

    const location = useLocation();
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [snackbarMessage, setMessage] = useState("");
    const [snackbarVariant, setVariant] = useState<AlertColor>("info");

    let message = useAppSelector(state => state.actionResult.message);
    let variant = useAppSelector(state => state.actionResult.variant);

    // callback to display messages pushed into history.location.state or redux store
    useEffect(() => {
        if (location.state) {
            let state = location.state;
            if (state.actionResult) {
                variant = state.actionResult.variant;
                message = state.actionResult.message;
                delete state.actionResult;
                navigate(location, { ...state });
            }
        }

        if (message !== "") {
            setOpen(true);
            setMessage(message);
            setVariant(variant);

            dispatch(actionsResultsActions.resetActionResult());
        }
    }, [message]);

    const handleClose = () => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpen(false);
        setMessage("");
        setVariant("info");
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            sx={{
                margin: '0 auto 2rem',
            }}
        >
            <Alert
                severity={snackbarVariant}
                onClose={handleClose}
                aria-describedby="message-id"
            >
                <Typography id="message-id" variant="body1" >
                    {snackbarMessage}
                </Typography>
            </Alert>
        </Snackbar>
    );
}

export default GlobalSnackbar;