import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Namespaces } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'helpers/forms';

export type CompleteAddressFormInputs = {
    street_address: TextInput,
    additional_information: TextInput,
    city: TextInput,
    postcode: TextInput,
}

interface CompleteAddressInputsProps {
    data: CompleteAddressFormInputs;
    onInputChange: (name: string, value: string) => void;
}

function CompleteAddressInputs(props: CompleteAddressInputsProps) {

    const { data, onInputChange, } = props;

    const { t } = useTranslation([Namespaces.forms,]);

    const getPlaceholder = (input: string) => {
        return t(`profile.${input}`, { ns: Namespaces.forms });
    }

    const { street_address, additional_information, city, postcode } = data;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    id="street_address"
                    label={getPlaceholder("street_address")}
                    value={street_address.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('street_address', event.target.value) }
                    }}
                    error={Boolean(street_address.error)}
                    helperText={street_address.error}
                    required
                />
            </Grid>
            <Grid item xs={12}>

                <TextField
                    id="address_complement"
                    label={getPlaceholder("additional_information")}
                    value={additional_information.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('additional_information', event.target.value) }
                    }}
                    error={Boolean(additional_information.error)}
                    helperText={additional_information.error}
                />
            </Grid>

            <Grid item xs={12}>

                <TextField
                    id="postcode"
                    label={getPlaceholder("postcode")}
                    value={postcode.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('postcode', event.target.value) }
                    }}
                    error={Boolean(postcode.error)}
                    helperText={postcode.error}
                    required
                />
            </Grid>

            <Grid item xs={12}>

                <TextField
                    id="city"
                    label={getPlaceholder("city")}
                    value={city.value}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        onChange: (event) => { onInputChange('city', event.target.value) }
                    }}
                    error={Boolean(city.error)}
                    helperText={city.error}
                    required
                />
            </Grid>
        </Grid>
    )
}

export default CompleteAddressInputs;